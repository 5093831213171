import { createSlice } from '@reduxjs/toolkit';

export const preloadLCPSlice = createSlice({
  name: 'preloadLCP',
  initialState: {
    shouldSkipGetNjoiRequest: false,
    shouldSkipGetNjoiComboRequest: false
  } as preloadLCPSliceState,
  reducers: {
    skipGetNjoiRequest: (state) => {
      state.shouldSkipGetNjoiRequest = true;
    },
    notSkipGetNjoiRequest: (state) => {
      state.shouldSkipGetNjoiRequest = false;
    },
    skipGetNjoiComboRequest: (state) => {
      state.shouldSkipGetNjoiComboRequest = true;
    },
    notSkipGetNjoiComboRequest: (state) => {
      state.shouldSkipGetNjoiComboRequest = false;
    }
  }
});

export const {
  skipGetNjoiRequest,
  notSkipGetNjoiRequest,
  skipGetNjoiComboRequest,
  notSkipGetNjoiComboRequest
} = preloadLCPSlice.actions;

export default preloadLCPSlice.reducer;
