import { useEffect, useState } from 'react';

const useResizeScreen = (breakpoint: number) => {
  const [isBiggerThanBreakpoint, setIsBiggerThanBreakpoint] = useState(
    window.innerWidth >= breakpoint ? true : false
  );

  useEffect(() => {
    const windowSizeHandler = () => {
      setIsBiggerThanBreakpoint(window.innerWidth >= breakpoint ? true : false);
    };

    window.addEventListener('resize', windowSizeHandler);

    return () => {
      window.removeEventListener('resize', windowSizeHandler);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isBiggerThanBreakpoint;
};
export default useResizeScreen;
