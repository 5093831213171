import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dependency, USECASE_TYPES } from 'njoi-core/inversify.config';
import { NjoiAnnouncement } from 'njoi-js-core/src/types/providers';
import {
  CloseAnnouncementUseCase,
  GetCloseAnnouncementStatusUseCase
} from 'njoi-js-core/src/types/useCases';

import { api } from '@store/api/api';

const announcementApi = api
  .enhanceEndpoints({ addTagTypes: ['announcement'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      fetchAnnouncementData: builder.query<NjoiAnnouncement, void>({
        query: () => ({
          useCaseType: USECASE_TYPES.GetAnnouncement
        })
      })
    })
  });
export const { useFetchAnnouncementDataQuery } = announcementApi;
export const { fetchAnnouncementData } = announcementApi.endpoints;

const initialState: AnnouncementState = {
  isClose: false,
  isRendered: false
};

export const announcementSlice = createSlice({
  name: 'announcement',
  initialState,
  reducers: {
    initAnnouncement(state, action: PayloadAction<{ isClose?: boolean }>) {
      const isCloseAnnouncement =
        Dependency.get<GetCloseAnnouncementStatusUseCase>(
          USECASE_TYPES.GetCloseAnnouncementStatus
        )();

      state.isClose = action.payload.isClose || isCloseAnnouncement;

      if (state.isClose) {
        Dependency.get<CloseAnnouncementUseCase>(
          USECASE_TYPES.CloseAnnouncement
        )();
      }

      return state;
    },
    onCloseAnnouncementBar(state) {
      Dependency.get<CloseAnnouncementUseCase>(
        USECASE_TYPES.CloseAnnouncement
      )();

      state.isClose = true;

      return state;
    },
    onCloseAnnouncemnetBarStoryBook(state) {
      state.isClose = false;
    },
    renderSuccess(state) {
      state.isRendered = true;
    }
  }
});

export const {
  initAnnouncement,
  onCloseAnnouncementBar,
  onCloseAnnouncemnetBarStoryBook,
  renderSuccess
} = announcementSlice.actions;

export default announcementSlice.reducer;
