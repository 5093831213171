import React from 'react';

import parse from 'html-react-parser';

import { AnnouncementContainer } from '@components/Based/Bar';
import { ClosableBar } from '@components/Core/TopBar';
import useRefetchApi from '@src/hooks/useRefetchApi';
import {
  useFetchAnnouncementDataQuery,
  onCloseAnnouncementBar,
  renderSuccess
} from '@src/store/announcement/AnnouncementSlice';
import { useAppSelector, useAppDispatch } from '@src/store/hooks';
import {
  fallbackImageHandler,
  getResizeImageUrl
} from '@src/web/helper/imageResizer/ImageResizer';

const DefaultAnnouncementBar: React.FC = () => {
  const { data, isFetching, refetch } = useFetchAnnouncementDataQuery();

  const { isClose } = useAppSelector((state) => state.announcement);

  const dispatch = useAppDispatch();
  useRefetchApi(refetch, [isClose], !isClose);

  if (isFetching) {
    return <div className="announcement-wrapper" />;
  }

  if (!data || isClose) {
    return <div></div>;
  }

  return (
    <ClosableBar
      className="announcement-wrapper"
      withVerticalPadding
      backgroundColor={data.colourCode}
      onClose={() => dispatch(onCloseAnnouncementBar())}
      isLightCloseButton={!data.isLightBackground}
    >
      <AnnouncementContainer>
        <div className="icon" onLoad={() => dispatch(renderSuccess())}>
          <img
            id="announcement-icon"
            data-testid="announcement-icon"
            src={getResizeImageUrl(data.imageUrl, 24, 24)}
            onError={fallbackImageHandler}
            alt="announcement-icon"
            width={24}
            height={24}
          />
        </div>
        <div id="announcement-desc" className="description">
          {parse(data.content)}
        </div>
      </AnnouncementContainer>
    </ClosableBar>
  );
};

export default DefaultAnnouncementBar;
