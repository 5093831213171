import styled from 'styled-components';

const buttonSize: {
  [size in ButtonSize]: {
    padding: string;
    fontSize: number;
    lineHeight: number;
    height: number;
    minWidth: number;
  };
} = {
  small: {
    padding: '14px 24px',
    fontSize: 14,
    lineHeight: 18,
    height: 48,
    minWidth: 247
  },
  default: {
    padding: '18px 24px',
    fontSize: 14,
    lineHeight: 18,
    height: 54,
    minWidth: 220
  },
  large: {
    padding: '18px 32px',
    fontSize: 18,
    lineHeight: 21,
    height: 57,
    minWidth: 220
  }
};

const linkButtonSize: {
  [size in ButtonSize]: {
    fontSize: number;
    lineHeight: number;
  };
} = {
  small: {
    fontSize: 14,
    lineHeight: 18
  },
  default: {
    fontSize: 16,
    lineHeight: 25
  },
  large: {
    fontSize: 18,
    lineHeight: 24
  }
};

export const Button = styled.button<ButtonProps>(
  ({ size, outlined, theme }) => `
  min-width: ${
    size ? buttonSize[size].minWidth : buttonSize.default.minWidth
  }px;
  padding: ${size ? buttonSize[size].padding : buttonSize.default.padding};
  border-radius: 50px;
  box-shadow: 0 7px 12px 0 rgba(33, 154, 112, 0.3);
  background-image: ${
    !outlined ? 'linear-gradient(90deg, #7DBF0D 0%, #1FB1A5 100%);' : 'none'
  };
  background-color: ${outlined ? theme.colors.neutral100 : 'transparent'};
  border: 1px solid ${outlined ? theme.colors.accent : 'transparent'};
  font-size: ${
    size ? buttonSize[size].fontSize : buttonSize.default.fontSize
  }px;
  font-weight: 500;
  font-style: normal;
  color: ${theme.colors[outlined ? 'accent' : 'neutral100']};
  text-transform: uppercase;
  cursor: pointer;
  line-height: ${
    size ? buttonSize[size].lineHeight : buttonSize.default.lineHeight
  }px;
  text-align: center;

  &:hover,
  &:active,
  &:focus {
    color: ${theme.colors.neutral100};
    background-image: ${
      outlined ? 'linear-gradient(90deg, #7DBF0D 0%, #1FB1A5 100%);' : 'none'
    };
    background-color: ${!outlined ? theme.colors.secondary2 : 'transparent'};
    border: 1px solid transparent;
  }

  &:is(.loading) {
    padding-left: 32px;
    cursor: default;
  }

  &:disabled {
    cursor: default;
    background-image: none;
    color: ${theme.colors.neutral100};
    box-shadow: none;
    background-color: ${theme.colors.borderColor};
    border: 1px solid transparent;
  }


  svg {
    width: 16px;
    height: 16px;
    margin-left: 10px;
  }
`
);

export const RoundButton = styled.button`
  width: 40px;
  height: 40px;
  object-fit: contain;
  opacity: 0.8;
  border-radius: 100px;
  border: 0;
  color: ${(props) => props.theme.colors.neutral100};
  background-color: ${(props) => props.theme.colors.paragraphs};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
`;

export const LinkButton = styled.button<LinkButtonProps>`
  position: relative;
  padding: 0;

  color: ${({ theme }) => theme.colors.accent};
  font-weight: 500;
  font-size: ${({ size }) =>
    `${
      size ? linkButtonSize[size].fontSize : linkButtonSize.default.fontSize
    }px`};
  line-height: ${({ size }) =>
    `${
      size ? linkButtonSize[size].lineHeight : linkButtonSize.default.lineHeight
    }px`};
  text-transform: uppercase;
  background: none;
  border: none;

  &::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    content: '>';
    margin-left: ${({ theme }) => theme.spaces.xxs};
  }

  &:hover,
  &:active,
  &:focus {
    color: #43954d;
    &::after {
      margin-left: ${({ theme }) => theme.spaces.xs};
    }
  }

  &:disabled {
    cursor: default;
    color: ${({ theme }) => theme.colors.neutral500};
    &:hover,
    &:active,
    &:focus {
      &::after {
        margin-left: ${({ theme }) => theme.spaces.xxs};
      }
    }
  }
`;

export const ChannelButtonContainer = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spaces.xs};
  border-radius: 16px;
  border: 1px solid #e5e5e5;
  background: ${({ theme }) => theme.colors.neutral100};
  box-sizing: border-box;
  height: 104px;
  width: 104px;
  padding: 12px;

  img {
    width: 90px;
    height: 64px;
  }

  p {
    margin: 0;
    width: fit-content;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.paragraphs};
  }

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.accent};
    p {
      color: ${({ theme }) => theme.colors.accent};
    }
  }

  &:focus {
    background: #f1fde7;
    animation: focus-animation 300ms ease-out;
  }

  @keyframes focus-animation {
    0% {
      background: ${({ theme }) => theme.colors.neutral100};
    }
    100% {
      background: #f1fde7;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.tabletMinWidth}) {
    width: 115px;
    height: 115px;
  }
`;

export const IconButton: React.FC<IconButtonProps> = ({
  src,
  className,
  onClick
}) => (
  <RoundButton
    data-testid="round-button-container"
    className={className}
    onClick={onClick}
  >
    <img src={src} alt="icon" />
  </RoundButton>
);
