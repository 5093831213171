import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import DefaultMenu from './DefaultMenu';
import { Col, Container, Row } from '@components/Based/Elements';
import {
  ArrowLeft,
  CloseIcon,
  MenuBurgerIcon,
  NjoiLogo,
  SearchIcon
} from '@components/Based/icons';
import { Header, Languages, SearchBar } from '@components/Based/Layout';
import { NjoiWebLink } from '@components/Core/Link';
import useResizeScreen from '@src/hooks/useResizeScreen';
import { toggleBanner } from '@src/store/banner/BannerSlice';
import { resetMenuAnimationState } from '@src/store/menuAnimation/menuAnimationSlice';
import { isURL } from '@src/web/helper/checker';
import {
  sendHeaderLanguageEvent,
  sendSearchResultLoadEvent,
  sendTopNavigationEvent
} from '@store/analytics/AnalyticSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { switchLanguage } from '@store/language/LanguageSlice';
import { dfKeyExtract } from '@web/helper/digitalFortressKeyExtract';

const DefaultHeader: React.FC<HeaderProps> = ({ openMenu, toggleMenu }) => {
  const history = useHistory();
  const params = useLocation().state;
  const { t } = useTranslation();
  const { pathname, search } = useLocation();

  const { language, isEN, isBM } = useAppSelector((state) => state.language);
  const isTabletScreenOrBigger = useResizeScreen(992);
  const [showMobileSearchBar, setShowMobileSearchBar] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(true);

  const searchBarTranslation = useAppSelector((state) =>
    dfKeyExtract(state, 'search_page')
  )?.[language.code];

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (showMobileSearchBar) {
      const input = document.getElementById('search-bar');
      input?.focus();
    }
  }, [showMobileSearchBar]);

  useEffect(() => {
    if (isTabletScreenOrBigger) {
      setShowMobileSearchBar(false);

      // Close the menu and reset menu animation state on mobile when
      // user resize from mobile to desktop view (if menu is open on mobile view)
      if (openMenu) {
        toggleMenu();
        dispatch(resetMenuAnimationState());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTabletScreenOrBigger]);

  useEffect(() => {
    setShowMobileSearchBar(false);

    if (isURL('search')) {
      setShowSearchBar(false);
    } else {
      setShowSearchBar(true);
    }
  }, [pathname]);

  const changeLanguage = (lang: LanguageTypeISO1) => {
    if (lang !== language.code) {
      const pathname = history.location.pathname;
      const searchBar = document.getElementById(
        'search-bar'
      ) as HTMLInputElement;

      if (searchBar) {
        searchBar.value = '';
      }

      if (openMenu) {
        toggleMenu();
      }

      if (pathname.length > 3) {
        const newPath = pathname.substr(0, 1) + lang + pathname.substr(1 + 2);

        dispatch(sendHeaderLanguageEvent({ language: lang, path: newPath }));

        if (isURL('search')) {
          history.push({ pathname: newPath, state: params, search: search });
        } else {
          history.push({ pathname: newPath, state: params });
        }
      }

      dispatch(switchLanguage(lang));
      dispatch(toggleBanner());
    }
  };

  const onSubmitSearchBar = (e: any) => {
    e.preventDefault();
    const searchValue = (
      document.getElementById('search-bar') as HTMLInputElement
    ).value;
    dispatch(
      sendSearchResultLoadEvent({
        event: 'clickEvent',
        title: searchValue,
        list_type: 'Search Box',
        type: 'NJOI'
      })
    );
    history.push({
      pathname: `/${language.code}-search`,
      search: `?q=${encodeURIComponent(searchValue)}`
    });
  };

  return (
    <Header>
      <Container>
        {showMobileSearchBar && showSearchBar ? (
          <Row className="mobile-menu-wrapper" alignItems="center">
            <Col>
              <div className="header-left">
                <button
                  data-testid="cancel-search-button"
                  className="mobile-cancel-search-button"
                  onClick={() => setShowMobileSearchBar(false)}
                >
                  <img src={ArrowLeft} alt="back" width="16" height="16" />
                </button>
              </div>
            </Col>
            <Col className="search-bar-wrapper">
              <SearchBar>
                <form onSubmit={onSubmitSearchBar}>
                  <img
                    className="search-icon"
                    src={SearchIcon}
                    alt="search-icon"
                    width="16"
                    height="16"
                  />
                  <input
                    id="search-bar"
                    placeholder={
                      searchBarTranslation?.searchBarPlaceholder ||
                      t('search.searchBar')
                    }
                    type="text"
                    maxLength={50}
                  />
                </form>
              </SearchBar>
            </Col>
          </Row>
        ) : (
          <Row className="mobile-menu-wrapper" alignItems="center">
            <Col>
              <div className="header-left">
                <button
                  className="mobile-menu-button"
                  onClick={toggleMenu}
                  data-testid="mobile-menu-button"
                >
                  <img
                    data-testid="mobile-menu-image"
                    src={openMenu ? CloseIcon : MenuBurgerIcon}
                    alt="menu"
                    width="24"
                    height="24"
                  />
                </button>
                <div className="logo">
                  {/* temporary link to NJOI webflow URL */}
                  <NjoiWebLink
                    path={`${language.code}-home`}
                    sendEvent={sendTopNavigationEvent({
                      title: 'NJOI Logo',
                      url: `${process.env.REACT_APP_NJOI_WEBFLOW_URL!}/${
                        language.code
                      }-home`
                    })}
                  >
                    <img
                      id="njoi-logo"
                      src={NjoiLogo}
                      alt="NJOI logo"
                      width="95"
                      height="32"
                    />
                  </NjoiWebLink>
                </div>
                {isTabletScreenOrBigger && (
                  <DefaultMenu
                    data-testid="default-menu"
                    openMenu={openMenu}
                    toggleMenu={toggleMenu}
                  />
                )}
              </div>
            </Col>
            {isTabletScreenOrBigger && showSearchBar ? (
              <Col className="search-bar-wrapper">
                <SearchBar>
                  <form onSubmit={onSubmitSearchBar}>
                    <img
                      className="search-icon"
                      src={SearchIcon}
                      alt="search-icon"
                      width="16"
                      height="16"
                    />
                    <input
                      id="search-bar"
                      placeholder={
                        searchBarTranslation?.searchBarPlaceholder ||
                        t('search.searchBar')
                      }
                      type="text"
                      maxLength={50}
                    />
                  </form>
                </SearchBar>
              </Col>
            ) : showSearchBar ? (
              <Col className="search-bar-wrapper">
                <button className="mobile-search-button">
                  <img
                    data-testid="mobile-search-icon"
                    onClick={() => setShowMobileSearchBar(true)}
                    className="search-icon"
                    src={SearchIcon}
                    alt="search-icon"
                    width="16"
                    height="16"
                  />
                </button>
              </Col>
            ) : null}
            <Col className="ml-auto">
              <Languages>
                <button
                  id="bm"
                  onClick={() => changeLanguage('bm')}
                  className={isBM ? 'active' : ''}
                  disabled={isBM ? true : false}
                >
                  BM
                </button>
                <button
                  id="en"
                  onClick={() => changeLanguage('en')}
                  className={isEN ? 'active' : ''}
                  disabled={isEN ? true : false}
                >
                  EN
                </button>
              </Languages>
            </Col>
          </Row>
        )}
        {!isTabletScreenOrBigger ? (
          <DefaultMenu
            data-testid="default-menu"
            openMenu={openMenu}
            toggleMenu={toggleMenu}
          />
        ) : null}
      </Container>
    </Header>
  );
};

export default DefaultHeader;
