import { USECASE_TYPES } from 'njoi-core/inversify.config';
import { NjoiCombo } from 'njoi-js-core/src/types/providers';

import { skipGetNjoiComboRequest } from '../preloadLCP/PreloadLCPSlice';
import { store } from '@src/store';
import { api } from '@store/api/api';

export const preloadNjoiCombo = async () => {
  await store.dispatch(fetchNjoiComboData.initiate());
  store.dispatch(skipGetNjoiComboRequest());
};

const njoiComboApi: any = api
  .enhanceEndpoints({ addTagTypes: ['njoiCombo'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      fetchNjoiComboData: builder.query<NjoiCombo, void>({
        query: () => ({ useCaseType: USECASE_TYPES.GetNjoiComboData })
      })
    })
  });

export const { useFetchNjoiComboDataQuery } = njoiComboApi;
export const { fetchNjoiComboData } = njoiComboApi.endpoints;
