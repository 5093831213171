import { createSlice } from '@reduxjs/toolkit';

export const redirectSlice = createSlice({
  name: 'redirect',
  initialState: {
    isNotFound: false
  } as RedirectSliceState,
  reducers: {
    // run this function if previous page is 404
    initRedirect: (state) => {
      if (state.isNotFound) {
        state.isNotFound = false;
      }
    },
    redirectNotFound: (state) => {
      state = {
        ...state,
        isNotFound: true
      };

      return state;
    }
  }
});

export const { initRedirect, redirectNotFound } = redirectSlice.actions;

export default redirectSlice.reducer;
