import { ReactNode, useEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';

import { useAppSelector } from './store/hooks';
import { dfKeyExtract } from './web/helper/digitalFortressKeyExtract';

const scriptRecord: { [key: string]: boolean } = {};

const ScriptWrapper = ({ children }: { children: ReactNode }) => {
  const scriptConfig = useAppSelector((state) =>
    dfKeyExtract(state, 'scripts')
  );
  const { pathname } = useLocation();
  const scriptRef = useRef<HTMLScriptElement[]>([]);
  const timeoutRef = useRef<number[]>([]);

  const scriptCallback = (
    inlineScript: HTMLScriptElement,
    shouldUseRef: boolean
  ) => {
    document.body.append(inlineScript);
    if (shouldUseRef) scriptRef.current.push(inlineScript);
  };

  const executeScript = (urlName: string) => {
    if (scriptConfig[urlName]) {
      const scripts = scriptConfig[urlName];

      for (let k = 0; k < scripts.length; k++) {
        if (scriptRecord[scripts[k].name]) {
          continue;
        }
        const scriptBody = scripts[k].body;
        const list = scriptBody.split('</script>');
        for (let j = 0; j < list.length - 1; j++) {
          const currentList = list[j];
          const temp = currentList.split('<script')[1];
          const [header, body] = temp.trim().split('>');
          const obj: any = {};
          let str = '';
          let isKey = true;
          /*
          For example, currentList= <script test='true' gg='false'>console.log(123);</script>
          const header = "test='true' gg='false'"
          const body = "console.log(123);</script>"
          obj={'test':'true', 'gg':'false'  }
          */
          for (let i = 0; i < header.length; i++) {
            if (header[i] === "'") continue;
            if (header[i] === '=') {
              obj[str] = '';
              isKey = false;
            } else if (!header[i].trim()) {
              isKey = true;
              str = '';
            } else {
              if (isKey) str += header[i];
              else obj[str] += header[i];
            }
          }
          const inlineScript = document.createElement('script');
          inlineScript.innerHTML = body;
          for (const [key, value] of Object.entries(obj)) {
            inlineScript.setAttribute(key, value as string);
          }
          if (scripts[k]?.type === 'once') {
            scriptRecord[scripts[k].name] = true;
          }
          const shouldUseRef = urlName !== 'all' && scripts[k]?.type !== 'once';
          if (scripts[k].delay) {
            const timeout = window.setTimeout(() => {
              scriptCallback(inlineScript, shouldUseRef);
            }, scripts[k].delay);
            if (shouldUseRef) {
              timeoutRef.current.push(timeout);
            }
          } else {
            scriptCallback(inlineScript, shouldUseRef);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (!Array.isArray(scriptConfig)) {
      try {
        executeScript('all');
      } catch (e) {
        console.error(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptConfig]);

  useEffect(() => {
    if (!Array.isArray(scriptConfig)) {
      const urlName = pathname.slice(4).split('/')[0];
      try {
        executeScript(urlName);
      } catch (e) {
        console.error(e);
      }
    }
    return () => {
      scriptRef.current.forEach((script: HTMLScriptElement) => {
        script.remove();
      });
      timeoutRef.current.forEach((timeout: number) => {
        clearTimeout(timeout);
      });
      scriptRef.current = [];
      timeoutRef.current = [];
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptConfig, pathname]);

  return <>{children}</>;
};

export default ScriptWrapper;
