import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  basic: {
    containerWidth: '1209px'
  },
  colors: {
    accent: '#53b952',
    titles: '#154635',
    paragraphs: '#333333',
    neutral100: '#ffffff',
    neutral200: '#f5f5f5',
    neutral300: '#eaeaea',
    neutral400: '#d5d5d5',
    neutral500: '#949494',
    neutral600: '#eeeeee',
    secondary1: '#f2ffe6',
    secondary2: '#33a332',
    secondary3: '#219a70',
    danger: '#ff3b30',
    placeholder: '#b2b3b8',
    skeleton: '#e9e9e9',
    focus: '#007aff',
    error: '#ff0000',
    gradient1: 'linear-gradient(123deg, #f2ffe6, #ccf8f2)',
    gradient2: 'linear-gradient(123deg, #7dbf0d, #1fb1a5)',
    gradient3: 'linear-gradient(to bottom, #f2ffe6, #ccf8f2)',
    gradient4: 'linear-gradient(to left, #6d4d97 0%, #df147d 100%)',
    gradient5:
      'linear-gradient(to left, #f78e10 5%, #f4513e 22%, #ca1f82 41%, #7b4694 65%, #2dc3e2 100%)',
    graident6: 'linear-gradient(97deg, #e6007d 0%, #e72f3b 100%)',
    secondary4: '#888888',
    secondary5: '#eee',
    tabTitle: '#53B952',
    borderColor: '#D9D9D9'
  },
  spaces: {
    xxs: '4px',
    xs: '8px',
    s: '16px',
    m: '24px',
    l: '32px',
    xl: '40px',
    xxl: '60px',
    xxl2: '80px',
    xxl3: '100px',
    xxl4: '48px',
    xxl5: '64px',
    xxl6: '72px '
  },
  breakpoint: {
    foldMaxWidth: '359px',
    mobileWidth: '576px',
    mobileMinWidth: '768px',
    mobileMaxWidth: '991px',
    tabletMinWidth: '992px',
    tabletMaxWidth: '1259px',
    desktopMinWidth: '1260px' // 1209 width + (24 * 2) left and right spacing
  }
};

export default theme;
