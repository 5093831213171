import { createSlice } from '@reduxjs/toolkit';

export const menuAnimationSlice = createSlice({
  name: 'menuAnimation',
  initialState: {
    isCloseMenuAnimationEnded: false,
    isOpenMenuAnimationEnded: false
  } as MenuAnimationSliceState,
  reducers: {
    resetMenuAnimationState: (state) => {
      state.isCloseMenuAnimationEnded = false;
      state.isOpenMenuAnimationEnded = false;
    },
    closeMenuAnimationEnded: (state) => {
      state.isCloseMenuAnimationEnded = true;
    },
    openMenuAnimationEnded: (state) => {
      state.isOpenMenuAnimationEnded = true;
    }
  }
});

export const {
  resetMenuAnimationState,
  closeMenuAnimationEnded,
  openMenuAnimationEnded
} = menuAnimationSlice.actions;

export default menuAnimationSlice.reducer;
