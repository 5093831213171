import { useEffect } from 'react';

import { useAppSelector } from '@src/store/hooks';

/**
 * @param {refetch} refetch refetch function from redux toolkit
 * @param {dependencies} dependencies list of dependencies for useEffect, except language code,
 * use `[]` if no extra dependencies needed (language code will be included by default)
 * @param {shouldRefetch} shouldRefetch to determine whether to call refetch, default is true
 */
const useRefetchApi = (
  refetch: () => void,
  dependencies: any[],
  shouldRefetch: boolean = true
) => {
  const { code } = useAppSelector((state) => state.language.language);

  useEffect(() => {
    if (shouldRefetch) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, ...dependencies]);
};

export default useRefetchApi;
