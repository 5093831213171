import '@locale/i18n';
import React from 'react';

import { Container } from 'inversify';
import { Provider as InversifyProvider } from 'inversify-react';
import { Dependency, MODEL_TYPES } from 'njoi-core/inversify.config';
import DevEnvironment from 'njoi-core/types/environment/DevEnvironment';
import { Environment } from 'njoi-js-core/src/types/models/environment';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';

import App from './App';
import reportHandler from './reportHandler';
import reportWebVitals from './reportWebVitals';
import { store } from '@src/store';

ReactDOM.render(
  <ReduxProvider store={store}>
    <React.StrictMode>
      <InversifyProvider
        container={Dependency.setup((container: Container) => {
          // Inject Platform specific configuration (i.e. React JS & React Native)
          container
            .bind<Environment>(MODEL_TYPES.Environment)
            .toDynamicValue(() => new DevEnvironment());
        })}
      >
        <App />
      </InversifyProvider>
    </React.StrictMode>
  </ReduxProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(reportHandler);
