import { createSelector } from '@reduxjs/toolkit';

const getPossibleEndpoints = (queries: any, endpointName: string) => {
  const possibleEndpointNames = Object.keys(queries).filter((name) =>
    name.includes(endpointName)
  );

  let possibleEndpoints: Query[] = [];
  possibleEndpointNames.forEach((endpointName: string) => {
    possibleEndpoints.push(queries[endpointName]);
  });

  return possibleEndpoints.sort(function (a: Query, b: Query) {
    return Number(a.fulfilledTimeStamp - b.fulfilledTimeStamp);
  });
};
/**
 * @name mutationExtract
 * @description Return the corresponding state by endpoint name
 * @param {state} state
 * @param {endpointName} string
 * @returns {data} object
 */

export const mutationExtract = createSelector(
  (state: any) => state.centralApi.mutation,
  (_: any, endpointName: string) => endpointName,
  (queries, endpointName) => {
    if (queries == null) {
      return undefined;
    }
    const possibleEndpoints = getPossibleEndpoints(queries, endpointName);

    return possibleEndpoints.length > 0
      ? possibleEndpoints[possibleEndpoints.length - 1].data
      : undefined;
  }
);

/**
 * @name queryExtract
 * @description Return the corresponding state by endpoint name
 * @param {state} state
 * @param {endpointName} string
 * @returns {data} object
 */

export const queryExtract = createSelector(
  (state: any) => state.centralApi.queries,
  (_: any, endpointName: string) => endpointName,
  (queries, endpointName) => {
    if (queries == null) {
      return undefined;
    }
    const possibleEndpoints = getPossibleEndpoints(queries, endpointName);

    return possibleEndpoints.length > 0
      ? {
          data: possibleEndpoints[possibleEndpoints.length - 1].data,
          error: possibleEndpoints[possibleEndpoints.length - 1].error,
          isFetching:
            possibleEndpoints[possibleEndpoints.length - 1].status === 'pending'
        }
      : undefined;
  }
);
