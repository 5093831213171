import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Dependency, USECASE_TYPES } from 'njoi-core/inversify.config';
import { CheckEntitledAstroFibreResponse } from 'njoi-js-core/src/types/providers';
import {
  RetrieveErrorResponseCode,
  ValidateEntitledAstroFibreUseCase,
  RetrieveErrorUseCase
} from 'njoi-js-core/src/types/useCases';

import { EntitledAstroFibreSlice } from '@src/types/store/entitledAstroFibre';

export const getEntitledAstroFibreData = createAsyncThunk<
  CheckEntitledAstroFibreResponse,
  string,
  { rejectValue: RetrieveErrorResponseCode }
>(
  'entitledastrofibre/entitledAstroFibreData',
  async (njoiToken, { rejectWithValue }) => {
    try {
      return await Dependency.get<ValidateEntitledAstroFibreUseCase>(
        USECASE_TYPES.ValidateEntitledAstroFibre
      )(njoiToken);
    } catch (error) {
      const {
        error: { response }
      } = error;
      const errorName = Dependency.get<RetrieveErrorUseCase>(
        USECASE_TYPES.RetrieveError
      )(response);
      throw rejectWithValue(errorName);
    }
  }
);

const initialState: EntitledAstroFibreSlice = {
  loading: false,
  error: null,
  njoiToken: '',
  entitledAstroFibreData: {
    smc: '',
    accountId: '',
    custSubtype: '',
    entitledAF: false
  }
};

export const entitledAstroFibreSlice = createSlice({
  name: 'entitledastrofibre',
  initialState: initialState as EntitledAstroFibreSlice,
  reducers: {
    saveNjoiToken(state, action) {
      state.njoiToken = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getEntitledAstroFibreData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload!;
      state.entitledAstroFibreData = {
        ...initialState.entitledAstroFibreData
      };
    });

    builder.addCase(getEntitledAstroFibreData.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.entitledAstroFibreData = action.payload;
    });

    builder.addCase(getEntitledAstroFibreData.pending, (state, action) => {
      state.loading = true;
      state.error = null;
      state.entitledAstroFibreData = {
        ...initialState.entitledAstroFibreData
      };
    });
  }
});

export const { saveNjoiToken } = entitledAstroFibreSlice.actions;

export default entitledAstroFibreSlice.reducer;
