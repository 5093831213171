import React, { useState } from 'react';

import { TopBarContainer } from '@components/Based/Bar';
import { ClosableBarProps } from '@src/types/components/core';

export const ClosableBar: React.FC<ClosableBarProps> = ({
  className,
  backgroundColor,
  withVerticalPadding,
  onClose,
  isLightCloseButton,
  children
}) => {
  const [closeBar, setCloseBar] = useState<boolean>(false);

  const onCloseBar = () => {
    onClose();
    setCloseBar(true);
  };

  if (closeBar) {
    return <div />;
  }

  return (
    <TopBarContainer
      data-testid="top-bar-container"
      className={className}
      backgroundColor={backgroundColor}
      withVerticalPadding={withVerticalPadding}
      isLightCloseButton={isLightCloseButton}
    >
      <div className="container">
        <div className="content">{children}</div>
        <div className="close-button">
          <button onClick={onCloseBar} aria-label="close">
            <span className="cross-mark"></span>
          </button>
        </div>
      </div>
    </TopBarContainer>
  );
};
