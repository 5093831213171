import styled, { keyframes } from 'styled-components';

export const Placeholder = styled.div`
  width: 100%;
  display: block;
`;

export const TransparentPlaceholder = styled(Placeholder)<PlaceholderProps>`
  height: ${({ height }) => height || '500px'};
  background: ${({ theme }) => theme.colors.neutral100};
`;

export const GradientPlaceholder = styled(Placeholder)<PlaceholderProps>`
  height: ${({ height }) => height || '100px'};
  background: ${({ theme }) => theme.colors.neutral100};
`;

const ShimmerKeyFrame = keyframes`
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(110%);
  }
`;

const ShimmerEffect = styled.div<ShimmerProps>`
  overflow: hidden;
  position: relative;
  background: ${({ backgroundColor, shimmerColor }) =>
    backgroundColor && shimmerColor ? `${backgroundColor}` : '#e9e9e9'};

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: ${({ backgroundColor, shimmerColor }) =>
      backgroundColor && shimmerColor
        ? `-webkit-gradient(linear, 100% 0, 0 0, from(${backgroundColor}), color-stop(0.5, ${shimmerColor}), to(${backgroundColor}))`
        : '-webkit-gradient(linear, 100% 0, 0 0, from(#e9e9e9), color-stop(0.5, #f5f5f5), to(#e9e9e9))'};

    background-repeat: no-repeat;
    animation-name: ${ShimmerKeyFrame};
    -webkit-animation-name: ${ShimmerKeyFrame};
    animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    background-size: 50px 100%;
    -webkit-background-size: 50px 100%;
  }
`;

export const ImageSkeleton = styled(ShimmerEffect)<ImageSkeletonProps>`
  width: 100%;
  padding-bottom: ${({ ratio }) => (ratio === '16/9' ? '56.25%' : '75%')};
  border-radius: ${({ hasBorderRadiusOnMobile }) =>
    hasBorderRadiusOnMobile === false ? '0px' : '16px'};

  @media (min-width: 992px) {
    border-radius: 16px;
  }
`;

//TitleSkeleton is default to left
export const TitleSkeleton = styled(ShimmerEffect)<TitleSkeletonProps>`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '27px'};
  border-radius: 8px;
  margin: ${({ margin, align }) =>
    align === 'right'
      ? `0 0 ${margin} auto`
      : align === 'center'
      ? `0 auto ${margin}`
      : `0 auto ${margin} 0`};
`;

//TextSkeleton is default to left
export const TextSkeleton = styled(ShimmerEffect)<TextSkeletonProps>`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '16px'};
  border-radius: 8px;
  margin: ${({ margin, align }) =>
    align === 'right'
      ? `0 0 ${margin} auto`
      : align === 'center'
      ? `0 auto ${margin}`
      : `0 auto ${margin} 0`};
`;

export const BannerSkeleton = styled(ShimmerEffect)`
  width: 100%;
  height: 100px;
  border-radius: 8px;
`;
