import { USECASE_TYPES } from 'njoi-core/inversify.config';
import { GetDigitalFortressConfig } from 'njoi-js-core/src/types/useCases';

import { api } from '@store/api/api';

const configApi = api
  .enhanceEndpoints({ addTagTypes: ['config'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      fetchHeaderConfig: builder.query<GetDigitalFortressConfig, void>({
        query: () => ({ useCaseType: USECASE_TYPES.GetConfig })
      })
    })
  });

export const { useFetchHeaderConfigQuery } = configApi;
export const { fetchHeaderConfig } = configApi.endpoints;
