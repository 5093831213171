import loadable from '@loadable/component';

import { injectReducer } from '@src/store';

export const HomeLoadableComponent = loadable(
  () => import(/* webpackChunkName: "Home" */ '@pages/Home')
);

export const PrivacyPolicyLoadableComponent = loadable(
  () => import(/* webpackChunkName: "PrivacyPolicy" */ '@pages/PrivacyPolicy')
);

export const PrivacyNoticeLoadableComponent = loadable(
  () => import(/* webpackChunkName: "PrivacyNotice" */ '@pages/PrivacyNotice')
);

export const TermsOfUseLoadableComponent = loadable(
  () => import(/* webpackChunkName: "TermsOfUse" */ '@pages/TermsOfUse')
);

export const TermsAndConditionsLoadableComponent = loadable(
  () =>
    import(
      /* webpackChunkName: "TermsAndConditions" */ '@pages/TermsAndConditions'
    )
);

// #region NJOI Help & Support
export const SupportLoadableComponent = loadable(() =>
  import(/* webpackChunkName: "Support" */ '@pages/Support').then(
    async (module: any) => {
      await injectReducer('support');
      return module;
    }
  )
);

export const SupportCategoriesLoadableComponent = loadable(() =>
  import(
    /* webpackChunkName: "SupportCategories" */ '@pages/Support/SupportCategories'
  ).then(async (module: any) => {
    await injectReducer('support');
    return module;
  })
);

export const SupportCategoryDetailLoadableComponent = loadable(
  () =>
    import(
      /* webpackChunkName: "SupportCategoryDetail" */ '@pages/Support/SupportCategoryDetail'
    )
);

export const SupportQuestionDetail = loadable(
  () =>
    import(
      /* webpackChunkName: "SupportQuestionDetail" */ '@pages/Support/SupportQuestionDetail'
    )
);
// #endregion NJOI Help & Support

// #region NJOI Store

export const GetNjoiLoadableComponent = loadable(() =>
  import(
    /* webpackChunkName: "GetNjoi" */ '@src/web/pages/Webstore/GetNjoi/GetNjoi'
  ).then(async (module: any) => {
    return module;
  })
);

export const NjoiStoreArticleDetailLoadableComponent = loadable(() =>
  import(
    /* webpackChunkName: "NjoiStoreArticleDetail" */ '@src/web/pages/Webstore/ArticleDetail/NjoiStoreArticleDetail'
  ).then(async (module: any) => {
    return module;
  })
);

export const NjoiStoreDistributorLoadableComponent = loadable(() =>
  import(
    /* webpackChunkName: "NjoiDistributor" */ '@src/web/pages/Webstore/NjoiDistributor/NjoiDistributor'
  ).then(async (module: any) => {
    return module;
  })
);

export const NjoiFormLoadableComponent = loadable(
  () =>
    import(
      /* webpackChunkName: "NjoiForm" */ '@src/web/pages/Webstore/NjoiForm/NjoiForm'
    )
);

export const NjoiFormB40LoadableComponent = loadable(
  () =>
    import(
      /* webpackChunkName: "NjoiForm" */ '@pages/Webstore/NjoiFormB40/NjoiFormB40'
    )
);

export const NjoiFormB40ThankYouLoadableComponent = loadable(
  () =>
    import(
      /* webpackChunkName: "NjoiFormB40ThankYou" */ '@pages/Webstore/NjoiFormB40ThankYou'
    )
);

export const ThankYouLoadableComponent = loadable(
  () =>
    import(
      /* webpackChunkName: "Thankyou" */ '@src/web/pages/Webstore/ThankYou/Thankyou'
    )
);

export const NjoiBoxMaxSubmissionLoadableComponent = loadable(
  () =>
    import(
      /* webpackChunkName: "NjoiBoxMaxSubmission" */ '@pages/Webstore/NjoiBoxMaxSubmissionPage'
    )
);

export const ChannelDetailLoadableComponent = loadable(
  () =>
    import(
      /* webpackChunkName: "ChannelDetail" */ '@src/web/pages/Webstore/ChannelDetail/ChannelDetail'
    )
);

export const BundleDetailLoadableComponent = loadable(
  () =>
    import(
      /* webpackChunkName: "BundleDetail" */ '@src/web/pages/Webstore/BundleDetail/BundleDetail'
    )
);

export const PackageDetailLoadableComponent = loadable(
  () =>
    import(
      /* webpackChunkName: "BundleDetail" */ '@src/web/pages/Webstore/PackageDetail/PackageDetail'
    )
);

// temporary disabled until further notice (upfront payment)
// export const UpfrontPaymentPageLoadableComponent = loadable(() =>
//   import(
//     /* webpackChunkName: "UpfrontPaymentConfirm" */ '@src/web/pages/Webstore/UpfrontPayment'
//   ).then(async (module: any) => {
//     await injectReducer('njoiForm');
//     return module;
//   })
// );

// #endregion NJOI Store

export const MovieDetailLoadableComponent = loadable(
  () =>
    import(
      /* webpackChunkName: "MovieDetail" */ '@src/web/pages/Webstore/MovieDetail/MovieDetail'
    )
);

export const SearchResultLoadableComponent = loadable(
  () =>
    import(
      /* webpackChunkName: "SearchResult" */ '@src/web/pages/Webstore/SearchResult'
    )
);

export const ChannelsPacksLoadableComponent = loadable(
  () =>
    import(
      /* webpackChunkName: "ChannelsPacks" */ '@src/web/pages/Webstore/ChannelsPacks'
    )
);

const routes = [
  {
    path: '',
    name: 'home',
    component: HomeLoadableComponent,
    exact: true
  },
  {
    path: 'support',
    name: 'support',
    component: SupportLoadableComponent,
    children: [
      {
        path: '',
        component: SupportCategoriesLoadableComponent,
        exact: true
      },
      {
        path: ':category',
        component: SupportCategoryDetailLoadableComponent,
        exact: true
      },
      {
        path: ':category/:question',
        component: SupportQuestionDetail,
        exact: true
      }
    ],
    exact: false
  },
  {
    path: 'privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicyLoadableComponent,
    exact: true
  },
  {
    path: 'privacy',
    name: 'privacy',
    component: PrivacyNoticeLoadableComponent,
    exact: true
  },
  {
    path: 'website-terms-of-use',
    name: 'website-terms-of-use',
    component: TermsOfUseLoadableComponent,
    exact: true
  },
  {
    path: 'terms-and-conditions',
    name: 'terms',
    component: TermsAndConditionsLoadableComponent,
    exact: true
  },
  {
    path: 'thank-you',
    name: 'thankyou',
    component: ThankYouLoadableComponent,
    exact: true
  },
  {
    path: 'get-njoi',
    name: 'getnjoi',
    component: GetNjoiLoadableComponent,
    exact: true
  },
  {
    path: 'get-njoi/distributor',
    name: 'get-njoi-distributor',
    component: NjoiStoreDistributorLoadableComponent,
    exact: true
  },
  {
    path: 'get-njoi/:content',
    name: 'get-njoi-content',
    component: NjoiStoreArticleDetailLoadableComponent,
    exact: true
  },
  {
    path: 'sign-up',
    name: 'signup',
    component: NjoiFormLoadableComponent,
    exact: true
  },
  {
    path: 'perpaduan-njoi-box-sign-up',
    name: 'perpaduan-njoi-box-signup',
    component: NjoiFormB40LoadableComponent,
    exact: true
  },
  {
    path: 'perpaduan-njoi-box-success-thank-you',
    name: 'perpaduan-njoi-box-success-thank-you',
    component: NjoiFormB40ThankYouLoadableComponent,
    exact: true
  },
  {
    path: 'perpaduan-njoi-box-pending-thank-you',
    name: 'perpaduan-njoi-box-pending-thank-you',
    component: NjoiFormB40ThankYouLoadableComponent,
    exact: true
  },
  {
    path: 'perpaduan-njoi-box-fail-thank-you',
    name: 'perpaduan-njoi-box-fail-thank-you',
    component: NjoiFormB40ThankYouLoadableComponent,
    exact: true
  },
  {
    path: 'perpaduan-njoi-box-max-submission',
    name: 'perpaduan-njoi-box-max-submission',
    component: NjoiBoxMaxSubmissionLoadableComponent,
    exact: true
  },
  {
    path: 'channels/:id',
    name: 'channeldetail',
    component: ChannelDetailLoadableComponent,
    exact: true
  },
  {
    path: 'packs/:id',
    name: 'bundledetail',
    component: PackageDetailLoadableComponent,
    exact: true
  },
  {
    path: 'bundles/:id',
    name: 'bundledetail',
    component: BundleDetailLoadableComponent,
    exact: true
  },
  {
    path: 'movies/:id',
    name: 'channeldetail',
    component: MovieDetailLoadableComponent,
    exact: true
  },
  // temporary disabled until further notice (upfront payment)
  // {
  //   path: 'upfront-payment',
  //   name: 'upfrontpayment',
  //   component: UpfrontPaymentPageLoadableComponent,
  //   exact: true
  // },
  {
    path: 'search',
    name: 'search',
    component: SearchResultLoadableComponent,
    exact: true
  },
  {
    path: 'channels-packs',
    name: 'channelpack',
    component: ChannelsPacksLoadableComponent,
    exact: false
  }
];

export default routes;
