import { Metric } from 'web-vitals';

// event values can only contain integers
const getEventValueFromMetric = (metric: Metric) => {
  if (metric.name === 'CLS') {
    return Math.round(metric.value * 1000);
  }
  return Math.round(metric.value);
};

const reportHandler = (metric: Metric) => {
  window.dataLayer = (window.dataLayer as any[]) || [];
  window.dataLayer.push({
    event: 'web-vitals',
    event_category: 'Web Vitals',
    event_action: metric.name,
    event_value: getEventValueFromMetric(metric),
    event_label: metric.id
  });
};

export default reportHandler;
