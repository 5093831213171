import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dependency, USECASE_TYPES } from 'njoi-core/inversify.config';
import { NjoiPromotion } from 'njoi-js-core/src/types/providers';
import {
  ClosePromotionUseCase,
  GetClosePromotionStatusUseCase
} from 'njoi-js-core/src/types/useCases';

import { api } from '@store/api/api';

const promotionApi = api
  .enhanceEndpoints({ addTagTypes: ['promotion'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      fetchPromotionData: builder.query<NjoiPromotion, void>({
        query: () => ({ useCaseType: USECASE_TYPES.GetPromotion })
      })
    })
  });

export const { useFetchPromotionDataQuery } = promotionApi;
export const { fetchPromotionData } = promotionApi.endpoints;

const initialState: PromotionState = {
  isClose: false,
  isRendered: false
};

export const promotionSlice = createSlice({
  name: 'promotion',
  initialState,
  reducers: {
    initPromotion(state, action: PayloadAction<{ isClose?: boolean }>) {
      const isClosePromotion = Dependency.get<GetClosePromotionStatusUseCase>(
        USECASE_TYPES.GetClosePromotionStatus
      )();

      state.isClose = action.payload.isClose || isClosePromotion;

      if (state.isClose) {
        Dependency.get<ClosePromotionUseCase>(USECASE_TYPES.ClosePromotion)();
      }

      return state;
    },
    onClosePromotionBar(state) {
      Dependency.get<ClosePromotionUseCase>(USECASE_TYPES.ClosePromotion)();

      state.isClose = true;

      return state;
    },
    onClosePromotionBarStoryBook(state) {
      state.isClose = false;
    },
    renderSuccess(state) {
      state.isRendered = true;
    }
  }
});

export const {
  initPromotion,
  onClosePromotionBar,
  onClosePromotionBarStoryBook,
  renderSuccess
} = promotionSlice.actions;

export default promotionSlice.reducer;
