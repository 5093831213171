import React from 'react';

import { PayloadAction } from '@reduxjs/toolkit';
import qs from 'query-string';
import { Link as ReactLink } from 'react-router-dom';

import {
  AnalyticLinkProps,
  ExternalLinkProps,
  NjoiWebLinkProps
} from '@src/types/components/core';
import { useAppDispatch, useAppSelector } from '@store/hooks';

export const AnalyticLink: React.FC<AnalyticLinkProps<PayloadAction<any>>> = ({
  external,
  url,
  title, // for top menu highlighted link
  sendEvent,
  className,
  onClick,
  onContextMenu,
  disable,
  ariaLabel,
  ariaLabelledBy,
  ariaDescribedBy,
  ...props
}) => {
  const dispatch = useAppDispatch();

  const onSendAnalytics = () => {
    if (sendEvent) {
      dispatch(sendEvent);
    }
  };

  const onClickAction = () => {
    if (onClick) {
      onClick();
    }

    onSendAnalytics();
  };

  const onClickContextMenu = () => {
    if (onContextMenu) {
      onContextMenu();
    }

    onSendAnalytics();
  };

  const onClickExternalLink = () => {
    window.localStorage.removeItem('channelDetail');
    onSendAnalytics();
  };

  if (disable) {
    return (
      <span
        className={`disable ${className || ''}`}
        {...{
          ...(title ? { title } : {})
        }}
      >
        {props.children}
      </span>
    );
  }

  if (external) {
    return (
      <a
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
        aria-describedby={ariaDescribedBy}
        href={url as string}
        onClick={onClickExternalLink}
        className={
          sendEvent?.payload.title
            ? sendEvent.payload.title
                .replace(/ /g, '-')
                .concat(className ? ` ${className}` : '')
            : className
            ? className
            : ''
        }
        {...{
          ...(typeof external === 'object' && external.openInNewTab
            ? { target: '_blank', rel: 'noreferrer' }
            : {})
        }}
      >
        {props.children}
      </a>
    );
  }

  return (
    <ReactLink
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
      to={url}
      onClick={onClickAction}
      onContextMenu={onClickContextMenu}
      className={
        sendEvent?.payload.title
          ? sendEvent.payload.title
              .replace(/ /g, '-')
              .concat(className ? ` ${className}` : '')
          : className
          ? className
          : ''
      }
      {...{
        ...(title ? { title } : {})
      }}
    >
      {props.children}
    </ReactLink>
  );
};

export const NjoiWebLink: React.FC<NjoiWebLinkProps<PayloadAction<any>>> = ({
  path,
  sendEvent,
  className,
  title,
  ...props
}) => {
  const { isClose: announcementIsClose } = useAppSelector(
    (state) => state.announcement
  );
  const { isClose: promotionIsClose } = useAppSelector(
    (state) => state.promotion
  );
  const { shouldShowBanner } = useAppSelector((state) => state.banner);

  const dispatch = useAppDispatch();

  const onClickLink = () => {
    window.localStorage.removeItem('channelDetail');
    onSendAnalytics();
  };

  const onSendAnalytics = () => {
    if (sendEvent) {
      dispatch(sendEvent);
    }
  };

  const params = {
    ...(announcementIsClose ? { isAnnouncementClose: true } : {}),
    ...(promotionIsClose ? { isPromotionClose: true } : {}),
    ...(!shouldShowBanner ? { isCampaignClose: true } : {})
  };

  const fullPath = `${path}${
    Object.keys(params).length ? `?${qs.stringify(params)}` : ''
  }`;

  return (
    <a
      className={className ? className : path}
      title={title}
      href={`${process.env.REACT_APP_NJOI_WEBFLOW_URL!}/${fullPath}`}
      onClick={onClickLink}
    >
      {props.children}
    </a>
  );
};

export const ExternalLink: React.FC<ExternalLinkProps> = ({
  path,
  className,
  title,
  ...props
}) => {
  const { isClose: announcementIsClose } = useAppSelector(
    (state) => state.announcement
  );
  const { isClose: promotionIsClose } = useAppSelector(
    (state) => state.promotion
  );
  const { shouldShowBanner } = useAppSelector((state) => state.banner);

  const onClickLink = () => {
    window.localStorage.removeItem('channelDetail');
  };

  const params = {
    ...(announcementIsClose ? { isAnnouncementClose: true } : {}),
    ...(promotionIsClose ? { isPromotionClose: true } : {}),
    ...(!shouldShowBanner ? { isCampaignClose: true } : {})
  };

  const fullPath = `${path}${
    Object.keys(params).length ? `?${qs.stringify(params)}` : ''
  }`;

  return (
    <a
      className={className ? className : path}
      title={title}
      href={fullPath}
      onClick={onClickLink}
    >
      {props.children}
    </a>
  );
};
