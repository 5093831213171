import styled from 'styled-components';

export const Container = styled.div`
  width: auto;
  padding: 0 ${(props) => props.theme.spaces.s};
  margin: 0 auto;

  @media (min-width: ${(props) => props.theme.breakpoint.desktopMinWidth}) {
    width: ${(props) => props.theme.basic.containerWidth};
    padding: 0 ${(props) => props.theme.spaces.m};
  }

  .mobile-menu-wrapper {
    position: relative;
    z-index: 11;
    background: white;
  }

  .search-bar-wrapper {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    .mobile-search-button {
      height: 24px;
      width: 24px;

      background: transparent;
      border: 0;
      padding: ${({ theme }) => theme.spaces.xxs};
      margin-right: ${({ theme }) => theme.spaces.xs};
    }
  }
`;

export const Row = styled.div<RowProps>`
  display: flex;
  margin: 0 -8px;
  flex-wrap: ${({ flexWrap }) => (flexWrap ? flexWrap : 'wrap')};
  ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent}`};
`;

export const Col = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 8px;

  &.m-full-width {
    width: 100%;
  }

  @media (min-width: ${(props) => props.theme.breakpoint.desktopMinWidth}) {
    width: auto !important;
  }
`;

export const BreadcrumbContainer = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    padding: 0;
    margin: 0;
    position: relative;
    display: inline-block;

    &:not(:last-child):after {
      content: '>';
      color: #888;
      display: inline-block;
      padding: 0 2px;
    }

    a,
    span {
      font-size: 12px;
    }

    a {
      color: ${({ theme }) => theme.colors.accent};
      span {
        color: ${({ theme }) => theme.colors.accent};
      }
    }

    span {
      color: #333;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.tabletMinWidth}) {
    li {
      a,
      span {
        font-size: 13px;
      }
    }
  }
`;
