import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import { Dependency, SERVICE_TYPES } from 'njoi-core/inversify.config';
import { AnalyticService } from 'njoi-js-core/src/types/services';

import { AstroFibreItems } from '@src/types/components/core';

const analyticService = (): AnalyticService =>
  Dependency.get(SERVICE_TYPES.Analytic);

export const analyticSlice: Slice = createSlice({
  name: 'analytics',
  initialState: {},
  reducers: {
    sendHeaderLanguageEvent: (
      state,
      action: PayloadAction<{ language: LanguageTypeISO1; path: string }>
    ) => {
      analyticService().sendEvent({
        event: 'navEvent',
        content_list_type: 'Language Toggle',
        content_title: action.payload.language,
        content_id: action.payload.path
      });

      return state;
    },
    sendTopNavigationEvent: (
      state,
      action: PayloadAction<{ title: string; url: string }>
    ) => {
      analyticService().sendEvent({
        event: 'navEvent',
        content_list_type: 'Top Navigation',
        content_title: action.payload.title,
        content_id: action.payload.url
      });

      return state;
    },
    sendTopUpEvent: (
      state,
      action: PayloadAction<{ title: string; url: string }>
    ) => {
      analyticService().sendEvent({
        event: 'clickEvent',
        content_list_type: 'Top Up',
        content_title: action.payload.title,
        content_id: action.payload.url
      });

      return state;
    },
    sendFooterContactUsNavigationEvent: (
      state,
      action: PayloadAction<{ title: string; url: string }>
    ) => {
      analyticService().sendEvent({
        event: 'navEvent',
        content_list_type: 'Footer Contact Us Navigation',
        content_title: action.payload.title,
        content_id: action.payload.url
      });

      return state;
    },
    sendSupportPageEvent: (
      state,
      action: PayloadAction<{
        event: SupportEventType;
        listType: string;
        title: string;
      }>
    ) => {
      analyticService().sendEvent({
        event: action.payload.event,
        content_type: 'NJOI Help & Support',
        content_list_type: action.payload.listType,
        content_title: action.payload.title
      });

      return state;
    },
    sendContactUsPageEvent: (
      state,
      action: PayloadAction<{ title: string; list_type: string }>
    ) => {
      analyticService().sendEvent({
        event: 'submitEvent',
        content_type: 'Get Njoi Box',
        content_list_type: action.payload.list_type,
        content_title: action.payload.title
      });

      return state;
    },
    sendWebstorePageEvent: (
      state,
      action: PayloadAction<{
        event: string;
        title: string;
        url: string;
        content_type: string;
        content_list_type: string;
      }>
    ) => {
      analyticService().sendEvent({
        event: action.payload.event,
        content_type: action.payload.content_type,
        content_list_type: action.payload.content_list_type,
        content_title: action.payload.title,
        content_id: action.payload.url
      });

      return state;
    },
    sendSignUpPageEvent: (
      state,
      action: PayloadAction<{
        event: string;
        title: string;
        url: string;
        content_type: string;
        content_list_type: string;
      }>
    ) => {
      analyticService().sendEvent({
        event: action.payload.event,
        content_type: action.payload.content_type,
        content_list_type: action.payload.content_list_type,
        content_title: action.payload.title,
        content_id: action.payload.url
      });
      return state;
    },
    clearPreviousEcommerce: (state) => {
      analyticService().sendEvent({
        ecommerce: null
      });

      return state;
    },
    sendThankyouPageEvent: (
      state,
      action: PayloadAction<{
        event: string;
        title: string;
        url: string;
        content_type: string;
        content_list_type: string;
      }>
    ) => {
      analyticService().sendEvent({
        event: action.payload.event,
        content_type: action.payload.content_type,
        content_list_type: action.payload.content_list_type,
        content_title: action.payload.title,
        content_id: action.payload.url
      });

      return state;
    },
    sendAstroFibrePageClickEvent: (
      state,
      action: PayloadAction<{
        event: string;
        item_category: string;
        item_list: string;
        item_name: string;
      }>
    ) => {
      analyticService().sendEvent({
        event: action.payload.event,
        item_category: action.payload.item_category,
        item_list: action.payload.item_list,
        item_name: action.payload.item_name
      });

      return state;
    },
    sendAstroFibrePageLoadEvent: (
      state,
      action: PayloadAction<{
        event: string;
        title: string;
      }>
    ) => {
      analyticService().sendEvent({
        event: action.payload.event,
        page_title: action.payload.title
      });

      return state;
    },
    sendAstroFibrePopUpEvent: (
      state,
      action: PayloadAction<{
        event: string;
        title: string;
        type: string;
      }>
    ) => {
      analyticService().sendEvent({
        event: action.payload.event,
        content_title: action.payload.title,
        content_type: action.payload.type
      });

      return state;
    },
    sendAstroFibreAddToCartEvent: (
      state,
      action: PayloadAction<{
        event: string;
        item_list_name: string;
        item_list_category: string;
        item_list_type: string;
        smartcard: string;
        quantity: number;
        currency: string;
        value?: string;
        tax?: string;
        ecommerce: {
          items: AstroFibreItems[];
        };
      }>
    ) => {
      analyticService().sendEvent({
        event: action.payload.event,
        item_list_name: action.payload.item_list_name,
        item_list_category: action.payload.item_list_category,
        item_list_type: action.payload.item_list_type,
        smartcard: action.payload.smartcard,
        quantity: action.payload.quantity,
        currency: action.payload.currency,
        value: action.payload.value!,
        tax: action.payload.tax!,
        ecommerce: {
          items: action.payload.ecommerce.items.map((data, index) => ({
            item_id: data.item_id,
            item_name: data.item_name,
            item_category: data.item_category,
            item_variant: data.item_variant,
            item_brand: data.item_brand,
            price: data.price,
            quantity: data.quantity
          }))
        }
      });
      return state;
    },
    sendWebstoreBundleEvent: (
      state,
      action: PayloadAction<{
        event: string;
        title: string;
        type: string;
        list_type: string;
        url: string;
      }>
    ) => {
      analyticService().sendEvent({
        event: action.payload.event,
        content_title: action.payload.title,
        content_type: action.payload.type,
        content_list_type: action.payload.list_type,
        content_id: action.payload.url
      });

      return state;
    },
    sendWebstoreWhatToWatchEvent: (
      state,
      action: PayloadAction<{
        event: string;
        title: string;
        type: string;
        list_type: string;
        url: string;
      }>
    ) => {
      analyticService().sendEvent({
        event: action.payload.event,
        content_title: action.payload.title,
        content_type: action.payload.type,
        content_list_type: action.payload.list_type,
        content_id: action.payload.url
      });

      return state;
    },
    sendNjoiPopUpEvent: (
      state,
      action: PayloadAction<{
        event: string;
        title: string;
        type: string;
      }>
    ) => {
      analyticService().sendEvent({
        event: action.payload.event,
        content_title: action.payload.title,
        content_type: action.payload.type
      });

      return state;
    },
    sendUpfrontPaymentClickEvent: (
      state,
      action: PayloadAction<{
        event: string;
        title: string;
        type: string;
        list_type: string;
      }>
    ) => {
      analyticService().sendEvent({
        event: action.payload.event,
        content_title: action.payload.title,
        content_type: action.payload.type,
        content_list_type: action.payload.list_type
      });

      return state;
    },
    sendNjoiAddToCartEvent: (
      state,
      action: PayloadAction<{
        event: string;
        item_list_name: string;
        item_list_category: string;
        item_list_type: string;
        quantity: number;
        currency: string;
        value?: string;
        ecommerce: {
          items: AstroFibreItems[];
        };
      }>
    ) => {
      analyticService().sendEvent({
        event: action.payload.event,
        item_list_name: action.payload.item_list_name,
        item_list_category: action.payload.item_list_category,
        item_list_type: action.payload.item_list_type,
        quantity: action.payload.quantity,
        currency: action.payload.currency,
        value: action.payload.value!,
        ecommerce: {
          items: action.payload.ecommerce.items.map((data, index) => ({
            item_name: data.item_name,
            item_category: data.item_category,
            price: data.price,
            quantity: data.quantity
          }))
        }
      });
      return state;
    },
    sendAddressInfoClickEvent: (
      state,
      action: PayloadAction<{
        event: string;
        title: string;
        type: string;
        list_type: string;
        category: string;
      }>
    ) => {
      analyticService().sendEvent({
        event: action.payload.event,
        content_title: action.payload.title,
        content_type: action.payload.type,
        content_list_type: action.payload.list_type,
        content_category: action.payload.category
      });

      return state;
    },
    sendSummaryPageClickEvent: (
      state,
      action: PayloadAction<{
        event: string;
        item_list: string;
        type: string;
        item_name: string;
      }>
    ) => {
      analyticService().sendEvent({
        event: action.payload.event,
        content_type: action.payload.type,
        item_name: action.payload.item_name,
        item_list: action.payload.item_list
      });

      return state;
    },
    sendChannelClickEvent: (
      state,
      action: PayloadAction<{
        event: string;
        id: string;
        type: string;
        list_type: string;
        title: string;
      }>
    ) => {
      analyticService().sendEvent({
        event: action.payload.event,
        content_type: action.payload.type,
        content_list_type: action.payload.list_type,
        content_id: action.payload.id,
        content_title: action.payload.title
      });

      return state;
    },
    sendApiFailEvent: (
      state,
      action: PayloadAction<{
        event: string;
        error_code: number;
        error_status: number;
        api_endpoint: string;
      }>
    ) => {
      analyticService().sendEvent({
        event: action.payload.event,
        error_code: action.payload.error_code,
        error_status: action.payload.error_status,
        api_endpoint: action.payload.api_endpoint
      });

      return state;
    },
    sendComingSoonLoadEvent: (
      state,
      action: PayloadAction<{
        event: string;
        list_type: string;
        type: string;
        title: string;
      }>
    ) => {
      analyticService().sendEvent({
        event: action.payload.event,
        content_type: action.payload.type,
        content_title: action.payload.title,
        content_list_type: action.payload.list_type
      });

      return state;
    },
    sendSearchResultLoadEvent: (
      state,
      action: PayloadAction<{
        event: string;
        list_type: string;
        type: string;
        title: string;
      }>
    ) => {
      analyticService().sendEvent({
        event: action.payload.event,
        content_type: action.payload.type,
        content_title: action.payload.title,
        content_list_type: action.payload.list_type
      });

      return state;
    },
    sendSearchResultCardEvent: (
      state,
      action: PayloadAction<{
        event: string;
        list_type: string;
        type: string;
        title: string;
        id: string;
      }>
    ) => {
      analyticService().sendEvent({
        event: action.payload.event,
        content_type: action.payload.type,
        content_title: action.payload.title,
        content_list_type: action.payload.list_type,
        content_id: action.payload.id
      });

      return state;
    },
    sendPurchaseViaAppEvent: (
      state,
      action: PayloadAction<{
        event: string;
        list_type: string;
        type: string;
        title: string;
        id: string;
      }>
    ) => {
      analyticService().sendEvent({
        event: action.payload.event,
        content_type: action.payload.type,
        content_title: action.payload.title,
        content_list_type: action.payload.list_type,
        content_id: action.payload.id
      });

      return state;
    },
    sendClickSignUpEvent: (
      state,
      action: PayloadAction<{
        event: string;
        list_type: string;
        type: string;
        title: string;
        id: string;
      }>
    ) => {
      analyticService().sendEvent({
        event: action.payload.event,
        content_type: action.payload.type,
        content_title: action.payload.title,
        content_list_type: action.payload.list_type,
        content_id: action.payload.id
      });

      return state;
    },
    sendClickLearnMoreEvent: (
      state,
      action: PayloadAction<{
        event: string;
        list_type: string;
        type: string;
        title: string;
        id: string;
      }>
    ) => {
      analyticService().sendEvent({
        event: action.payload.event,
        content_type: action.payload.type,
        content_title: action.payload.title,
        content_list_type: action.payload.list_type,
        content_id: action.payload.id
      });

      return state;
    },
    sendChannelsAndPacksPageClickEventWithID: (
      state,
      action: PayloadAction<{
        list_type: string;
        title: string;
        id: string;
      }>
    ) => {
      analyticService().sendEvent({
        event: 'clickEvent',
        content_type: 'NJOI Channels & Packs',
        content_title: action.payload.title,
        content_list_type: action.payload.list_type,
        content_id: action.payload.id
      });

      return state;
    },
    sendChannelsAndPacksPageClickEvent: (
      state,
      action: PayloadAction<{
        list_type: string;
        title: string;
      }>
    ) => {
      analyticService().sendEvent({
        event: 'clickEvent',
        content_type: 'NJOI Channels & Packs',
        content_title: action.payload.title,
        content_list_type: action.payload.list_type
      });

      return state;
    },
    sendTopPurchaseClickEvent: (
      state,
      action: PayloadAction<{
        title: string;
        id: string;
        position: number;
      }>
    ) => {
      analyticService().sendEvent({
        event: 'clickEvent',
        content_type: 'NJOI Channels & Packs',
        content_title: action.payload.title,
        content_list_type: 'Top 10 Purchase',
        content_id: action.payload.id,
        content_list_position: action.payload.position
      });

      return state;
    },
    sendChannelsAndPacksPageNavEvent: (
      state,
      action: PayloadAction<{
        title: string;
      }>
    ) => {
      analyticService().sendEvent({
        event: 'navEvent',
        content_type: 'NJOI Channels & Packs',
        content_title: action.payload.title,
        content_list_type: 'Top Navigation'
      });

      return state;
    },
    sendChatWithUsEvent: (
      state,
      action: PayloadAction<{
        url: string;
      }>
    ) => {
      analyticService().sendEvent({
        event: 'clickEvent',
        content_type: 'NJOI',
        content_title: 'Whatsapp',
        content_list_type: 'Chat With Us',
        content_id: action.payload.url
      });

      return state;
    },
    sendB40FormNavigationEvent: (
      state,
      action: PayloadAction<{
        name: string;
      }>
    ) => {
      analyticService().sendEvent({
        event: 'navigation',
        content_type: 'NJOI B40 Sign Up Form',
        item_list: 'CTA',
        item_name: action.payload.name
      });

      return state;
    },
    sendB40FormClickEvent: (
      state,
      action: PayloadAction<{
        pageTitle: string;
        title: string;
      }>
    ) => {
      analyticService().sendEvent({
        event: 'clickEvent',
        content_type: 'NJOI',
        content_list_type: 'NJOI B40 Sign Up Form',
        content_title: action.payload.pageTitle,
        content_id: action.payload.title
      });

      return state;
    },
    sendB40FormSubmitEvent: (
      state,
      action: PayloadAction<{
        type: string;
      }>
    ) => {
      analyticService().sendEvent({
        event: 'submitEvent',
        content_type: 'NJOI B40 Sign Up Form',
        content_title: 'Pakej Perpaduan Box',
        content_list_type: action.payload.type
      });

      return state;
    },
    getNjoiClickEvent: (
      state,
      action: PayloadAction<{
        event: string;
        type: string;
        list_type: string;
        title: string;
        url: string;
      }>
    ) => {
      analyticService().sendEvent({
        event: action.payload.event,
        content_type: action.payload.type,
        content_list_type: action.payload.list_type,
        content_title: action.payload.title,
        content_id: action.payload.url
      });

      return state;
    },
    getNjoiPlusIconExpandEvent: (
      state,
      action: PayloadAction<{
        event: string;
        type: string;
        list_type: string;
        title: string;
      }>
    ) => {
      analyticService().sendEvent({
        event: action.payload.event,
        content_type: action.payload.type,
        content_list_type: action.payload.list_type,
        content_title: action.payload.title
      });

      return state;
    }
  }
});

export const {
  sendHeaderLanguageEvent,
  sendTopNavigationEvent,
  sendTopUpEvent,
  sendFooterContactUsNavigationEvent,
  sendSupportPageEvent,
  sendContactUsPageEvent,
  sendWebstorePageEvent,
  sendSignUpPageEvent,
  sendThankyouPageEvent,
  sendAstroFibrePageClickEvent,
  sendAstroFibrePageLoadEvent,
  sendAstroFibrePopUpEvent,
  sendAstroFibreAddToCartEvent,
  clearPreviousEcommerce,
  sendWebstoreBundleEvent,
  sendWebstoreWhatToWatchEvent,
  sendNjoiPopUpEvent,
  sendUpfrontPaymentClickEvent,
  sendNjoiAddToCartEvent,
  sendAddressInfoClickEvent,
  sendSummaryPageClickEvent,
  sendChannelClickEvent,
  sendApiFailEvent,
  sendComingSoonLoadEvent,
  sendSearchResultLoadEvent,
  sendSearchResultCardEvent,
  sendPurchaseViaAppEvent,
  sendClickSignUpEvent,
  sendClickLearnMoreEvent,
  sendChannelsAndPacksPageClickEventWithID,
  sendChannelsAndPacksPageClickEvent,
  sendTopPurchaseClickEvent,
  sendChannelsAndPacksPageNavEvent,
  sendChatWithUsEvent,
  sendB40FormNavigationEvent,
  sendB40FormClickEvent,
  sendB40FormSubmitEvent,
  getNjoiClickEvent,
  getNjoiPlusIconExpandEvent
} = analyticSlice.actions;

export default analyticSlice.reducer;
