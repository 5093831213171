import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18n from 'i18next';
import { Dependency, USECASE_TYPES } from 'njoi-core/inversify.config';
import {
  SetLanguageUseCase,
  GetLanguageUseCase
} from 'njoi-js-core/src/types/useCases';

import config from '@src/config';

const isEN = (language: LanguageTypeISO2) => language === 'eng';

export const languageSlice = createSlice({
  name: 'language',
  initialState: {
    language: config.defaultLanguage,
    isEN: isEN(config.defaultLanguage.iso),
    isBM: !isEN(config.defaultLanguage.iso)
  } as LanguageState,
  reducers: {
    initiateLanguage() {
      const firstPath = window.location.pathname.split('/')[1];
      const prefixLang = firstPath.split('-')[0] as LanguageTypeISO1;

      if (
        prefixLang &&
        !!config.supportedLanguages.find((lang) => lang.code === prefixLang)
      ) {
        const lang = config.supportedLanguages.find(
          (lang) => lang.code === prefixLang
        )!;

        Dependency.get<SetLanguageUseCase>(USECASE_TYPES.SetLanguage)(lang.iso);
        i18n.changeLanguage(prefixLang);

        return {
          language: lang,
          isEN: isEN(lang.iso),
          isBM: !isEN(lang.iso)
        };
      } else {
        const language = Dependency.get<GetLanguageUseCase>(
          USECASE_TYPES.GetLanguage
        )();
        i18n.changeLanguage(language);

        const lang = config.supportedLanguages.find(
          (lang) => lang.iso === language
        )!;

        return {
          language: lang,
          isEN: isEN(language),
          isBM: !isEN(language)
        };
      }
    },
    switchLanguage(_, action: PayloadAction<LanguageTypeISO1>) {
      const language = config.supportedLanguages.find(
        (lang) => lang.code === action.payload
      )!;

      i18n.changeLanguage(language.code);
      Dependency.get<SetLanguageUseCase>(USECASE_TYPES.SetLanguage)(
        language.iso
      );

      return {
        language,
        isEN: isEN(language.iso),
        isBM: !isEN(language.iso)
      };
    }
  }
});

export const { initiateLanguage, switchLanguage } = languageSlice.actions;

export default languageSlice.reducer;
