import React, { useEffect } from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import { TransparentPlaceholder } from '@components/Based/Placeholder';

const NjoiRoute: React.FC<any> = ({
  path,
  fullPath,
  childComponent,
  component: Component
}) => {
  useEffect(() => {
    if (path === '') {
      window.location.href = process.env.REACT_APP_NJOI_WEBFLOW_URL!;
    }
  }, [path]);

  return (
    <Component fallback={<TransparentPlaceholder />}>
      {childComponent && childComponent.length > 0 && (
        <Switch>
          {childComponent.map(
            ({ path, exact, component: SubComponent }: any) => (
              <Route
                key={path}
                path={`${fullPath}/${path}`}
                render={() => (
                  <SubComponent fallback={<TransparentPlaceholder />} />
                )}
                exact={exact}
              />
            )
          )}
          <Redirect to={fullPath} />
        </Switch>
      )}
    </Component>
  );
};

export default NjoiRoute;
