import styled from 'styled-components';

export const TopBarContainer = styled.div<TopBarContainerProps>`
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.colors.secondary1};
  position: relative;
  z-index: 11;

  .container {
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    padding: ${({ theme, withVerticalPadding }) =>
      `${withVerticalPadding ? theme.spaces.s : 0} ${theme.spaces.s}`};

    .content {
      width: 100%;
    }

    .close-button {
      overflow: hidden;
      position: absolute;
      top: 50%;
      right: ${({ theme }) => theme.spaces.s};
      transform: translateY(-50%);

      button {
        padding: 0;
        border: 0;
        float: right;
        display: block;
        position: relative;
        width: 24px;
        height: 24px;
        background-color: transparent;
        .cross-mark {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 100%;
          &::before,
          &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            background-color: ${({ isLightCloseButton }) =>
              isLightCloseButton ? '#fff' : '#333'};
          }

          &::before {
            width: 18px;
            height: 2px;
          }

          &::after {
            height: 18px;
            width: 2px;
          }
        }
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.desktopMinWidth}) {
    .container {
      width: 1280px;
      margin: 0 auto;
      padding: ${({ withVerticalPadding, theme }) =>
        `${withVerticalPadding ? '12px' : 0} ${theme.spaces.m}`};

      .close-button {
        right: ${({ theme }) => theme.spaces.m};
      }
    }
  }
`;

export const AnnouncementContainer = styled.div`
  width: 90%;
  display: flex;
  align-items: center;

  .icon {
    width: 10%;
    min-width: 24px;
    overflow: hidden;

    img {
      width: 24px;
      height: 24px;
      display: block;
      float: right;
    }
  }

  .description {
    margin: 0 12px;

    p {
      margin: 0;
    }
  }
`;

export const PromotionContainer = styled.div`
  display: flex;
  align-items: center;

  .icon {
    width: 100%;
    overflow: hidden;

    a {
      max-width: 1209px;
      display: block;
      margin: 0 auto;
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: contain;
      margin: auto;
    }
  }
`;
