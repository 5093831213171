import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { Dependency, SERVICE_TYPES } from 'njoi-core/inversify.config';

const defaultState: MetaState = {
  title: '',
  description: '',
  image: '',
  url: '',
  keywords: '',
  ogTitle: '',
  ogDescription: '',
  ogImage: '',
  ogUrl: '',
  twitterTitle: '',
  twitterDescription: '',
  twitterImage: '',
  twitterUrl: ''
};

export const metaSlice = createSlice({
  name: 'meta',
  initialState: defaultState,
  reducers: {
    updateMeta: (state, action: PayloadAction<MetaPayload>) => {
      const { title, description, image, url, keywords } = action.payload;

      // const getSeoService = Dependency.get<SeoService>(SERVICE_TYPES.Seo);
      const defaultMetaImage: string = 'test';

      return {
        title,
        description: description,
        image: image || defaultMetaImage,
        url,
        keywords: keywords || '',
        ogTitle: action.payload.ogTitle || title,
        ogDescription: action.payload.ogDescription || description,
        ogImage: action.payload.ogImage || image || defaultMetaImage,
        ogUrl: action.payload.ogUrl || url,
        twitterTitle: action.payload.twitterTitle || title,
        twitterDescription: action.payload.twitterDescription || description,
        twitterImage: action.payload.twitterImage || image || defaultMetaImage,
        twitterUrl: action.payload.twitterUrl || url
      };
    }
  }
});

export const { updateMeta } = metaSlice.actions;

export default metaSlice.reducer;
