export const getYearPrefix = (year: string) => {
  let correctPrefix: string = '';
  if (year.length === 2) {
    const currentYear: number = new Date().getFullYear(); // example 2012
    const currentYearPrefix: number = Math.floor(currentYear / 100); // 20
    const currentYearPostfix: string = (currentYear % 100).toString(); //12
    correctPrefix = (
      currentYearPrefix - (currentYearPostfix > year ? 0 : 1)
    ).toString();
  }
  return correctPrefix;
};

/**
 * @name isValidDate
 * @description check if the given date is valid (including leap year)
 * @param {string} year [97, 01, 10] or [1997, 2001, 2010]
 * @param {string} month [01, 02, 03, ... 12]
 * @param {string} day [01, 02, 03, ... 31]
 * @returns {boolean} isValid
 */
export const isValidDate = (
  year: string,
  month: string,
  day: string
): boolean => {
  let yearPrefix: string = getYearPrefix(year);
  const date = new Date(`${yearPrefix}${year}-${month}-${day}`);
  return !isNaN(date.getTime()) && Number(month) === date.getMonth() + 1;
};

export const isWithinValidAge = (
  birthday: Date,
  minAge: number,
  maxAge: number = 1000
) => {
  const currentDate = new Date();
  const yearDiff = currentDate.getFullYear() - birthday.getFullYear();

  if (yearDiff >= minAge && yearDiff <= maxAge) {
    if (yearDiff > minAge) {
      return true;
    }

    if (currentDate.getMonth() > birthday.getMonth()) {
      return true;
    }

    if (
      currentDate.getMonth() === birthday.getMonth() &&
      currentDate.getDate() >= birthday.getDate()
    ) {
      return true;
    }
  }

  return false;
};

export const isURL = (
  ...url: ('get-njoi' | 'sign-up' | 'search')[]
): boolean => {
  // /^\/[a-zA-Z]+-(url1|url2)($|\?.*)/
  return new RegExp('^\\/[a-zA-Z]+-(' + url.join('|') + ')($|\\?.*)').test(
    window.location.pathname
  );
};

export const isEmptyObj = (obj: object) => {
  for (let i in obj) return false;
  return true;
};
