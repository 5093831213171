import styled from 'styled-components';

import { Col, Container, Row } from './Elements';

export const TopUpBox = styled.div`
  margin-top: 18px;
  margin-bottom: ${(props) => props.theme.spaces.m};
  padding: 0 ${(props) => props.theme.spaces.s};
  display: none;
  z-index: 1;

  &.open,
  &.close {
    display: block;
  }

  p {
    margin: 0;
  }

  ${Col} {
    display: block;
  }

  .app-download-qr-code {
    display: none;
  }

  .app-store-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    margin-top: ${(props) => props.theme.spaces.s};
    margin-bottom: ${(props) => props.theme.spaces.m};

    li {
      margin-right: ${(props) => props.theme.spaces.xxs};

      a {
        display: block;
        padding: 0;

        img {
          display: block;
          height: 31px;
        }
      }
    }
  }

  .download-app-button {
    margin-bottom: ${(props) => props.theme.spaces.m};
  }

  @media (min-width: ${(props) => props.theme.breakpoint.tabletMinWidth}) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding: ${(props) => props.theme.spaces.m};
    border-radius: 16px;
    box-shadow: 0 0 12px 0 rgba(148, 148, 148, 0.3);
    background-color: ${(props) => props.theme.colors.neutral100};

    display: block;
    visibility: hidden;
    margin-top: ${(props) => props.theme.spaces.s};
    opacity: 0;
    top: 120%;
    transition: opacity 0.3s, top 0.3s, visibility 0.3s step-end;

    &.open,
    &.close {
      opacity: 1;
      top: 100%;
      visibility: visible;
    }

    ${Col} {
      display: flex;
    }

    .app-download-qr-code {
      display: block;

      img {
        width: 215px;
      }
    }

    .app-store-list {
      margin-top: ${(props) => props.theme.spaces.xs};
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoint.foldMaxWidth}) {
    .app-store-list {
      flex-direction: column;

      li {
        margin-right: 0;
        margin-bottom: ${(props) => props.theme.spaces.xxs};
      }
    }
  }
`;

export const MenuList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  list-style: none;

  & > li {
    position: relative;

    & > span,
    & > a {
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: ${(props) => props.theme.colors.paragraphs};

      svg {
        margin-left: ${(props) => props.theme.spaces.xxs};
      }

      &:hover {
        color: ${(props) => props.theme.colors.accent};

        svg {
          fill: ${(props) => props.theme.colors.accent};
        }
      }

      &.active {
        color: rgba(255, 255, 255, 0);

        &:after {
          content: attr(title);
          display: flex;
          align-items: center;
          position: absolute;
          top: initial;
          left: initial;
          font-weight: bold;
          line-height: normal;
          color: ${(props) => props.theme.colors.accent};
          line-height: 1.25;
          white-space: nowrap;
        }

        svg {
          fill: ${(props) => props.theme.colors.accent};
        }
      }

      &:hover {
        text-decoration: none;
      }

      &.disable {
        cursor: default;
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoint.mobileMaxWidth}) {
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 10;
    background-color: ${(props) => props.theme.colors.neutral100};
    overflow: auto;
    padding: ${(props) => props.theme.spaces.m} 0 0;
    height: 100vh;

    &.fit-content {
      height: fit-content;
    }

    &.open {
      animation: slideIn 1000ms;
      animation-timing-function: ease;

      @keyframes slideIn {
        from {
          transform: translateY(-100%);
        }
        to {
          transform: translateY(0%);
        }
      }
    }

    &.close {
      animation: slideOut 1000ms;
      animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);

      @keyframes slideOut {
        from {
          transform: translateY(0%);
        }
        to {
          transform: translateY(-100%);
        }
      }
    }

    & > li {
      &:last-child {
        padding-bottom: ${(props) => props.theme.spaces.m};
      }

      & > span,
      & > a {
        padding: 0 ${(props) => props.theme.spaces.s};
        margin-bottom: ${(props) => props.theme.spaces.m};

        svg {
          margin-left: ${(props) => props.theme.spaces.xxs};
        }
      }

      & > span {
        &.active {
          margin-bottom: ${(props) => props.theme.spaces.m};
          color: transparent !important;

          svg {
            fill: ${(props) => props.theme.colors.accent} !important;
          }
        }

        &:hover {
          color: ${(props) => props.theme.colors.paragraphs};

          svg {
            fill: ${(props) => props.theme.colors.paragraphs};
          }
        }
      }
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoint.tabletMinWidth}) {
    & > li {
      &:not(:last-child) {
        margin-right: ${(props) => props.theme.spaces.l};
      }
    }
  }
`;

export const SearchBar = styled.div`
  position: relative;
  width: 100%;

  .search-icon {
    position: absolute;
    top: 14px;
    left: 16px;
  }

  // To overwrite autofill user agent style
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  input {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.paragraphs};
    height: 44px;
    width: 100%;
    border-radius: 50px;
    border: 1px solid #d9d9d9;
    padding: ${({ theme }) => `12px ${theme.spaces.s} 12px ${theme.spaces.xl}`};
    background-color: #ffffff;
    outline: none !important;
    box-sizing: border-box;
  }

  input:focus {
    border: ${({ theme }) => `1px solid ${theme.colors.accent}`};
  }

  input::placeholder {
    color: #bbbbbb;
  }

  @media (min-width: ${(props) => props.theme.breakpoint.tabletMinWidth}) {
    margin: ${({ theme }) => `0 ${theme.spaces.xs}`};
    width: 100%;
    min-width: 0;
    max-width: 220px;
  }
`;

export const Languages = styled.div`
  display: flex;

  button {
    display: block;
    border: 0;
    padding: 0;
    background: none;
    color: ${(props) => props.theme.colors.paragraphs};

    &.active {
      font-weight: bold;
      color: ${(props) => props.theme.colors.accent};
    }

    &:not(:last-child) {
      position: relative;
      margin-right: ${(props) => props.theme.spaces.s};

      &:after {
        content: '';
        width: 1px;
        height: 18px;
        background-color: ${(props) => props.theme.colors.neutral500};
        position: absolute;
        right: -9px;
      }
    }
  }
`;

export const Header = styled.header`
  position: relative;

  & > ${Container} {
    position: relative;

    & > ${Row} {
      height: 60px;
    }
  }

  .header-left {
    display: flex;
    align-items: center;

    .mobile-menu-button {
      border: 0;
      padding: 0;
      background: transparent;
      margin-right: ${(props) => props.theme.spaces.m};

      img {
        display: block;
      }
    }

    .mobile-cancel-search-button {
      height: 24px;
      width: 24px;
      margin-right: ${({ theme }) => theme.spaces.xs};

      background: transparent;
      border: 0;
      padding: ${({ theme }) => theme.spaces.xxs};
    }

    .logo {
      display: block;

      img {
        width: 54px;
        height: auto;
        display: block;
      }
    }
  }

  ${MenuList} {
    display: none;

    &.open,
    &.close {
      display: block;
    }
  }

  .promotion-wrapper {
    min-height: 50px;
  }

  .announcement-wrapper {
    min-height: 80px;
  }

  @media screen and (min-width: 600px) and (max-width: ${(props) =>
      props.theme.breakpoint.tabletMaxWidth}) {
    .promotion-wrapper,
    .announcement-wrapper {
      min-height: 40px;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoint.desktopMinWidth}) {
    .announcement-wrapper {
      min-height: 60px;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoint.tabletMinWidth}) {
    & > ${Container} {
      & > ${Row} {
        height: 80px;
      }
    }

    .header-left {
      .mobile-menu-button {
        display: none;
      }

      .logo {
        margin-right: ${(props) => props.theme.spaces.xxl};

        img {
          width: 116px;
          height: auto;
        }
      }
    }

    ${MenuList} {
      display: flex !important;
    }
  }
`;

export const ExpandableMenu = styled.div`
  width: 100%;
  display: block;
  margin-bottom: 26px;

  .menu-title {
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      display: block;
      fill: ${(props) => props.theme.colors.neutral100};
    }
  }

  ul {
    list-style: none;
    margin: 16px 0 0;
    padding: 0;
    display: none;

    &.open,
    &.close {
      display: block;
    }

    li {
      &:not(:last-child) {
        margin-bottom: 16px;
      }

      a {
        color: ${(props) => props.theme.colors.neutral100};
      }
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoint.desktopMinWidth}) {
    width: auto;
    margin-bottom: 0;

    &:not(:last-child) {
      margin-right: 90px;
    }

    .menu-title {
      svg {
        display: none;
      }
    }

    ul {
      display: block;
    }
  }
`;

export const Footer = styled.footer`
  color: ${(props) => props.theme.colors.neutral100};
  padding: ${(props) => props.theme.spaces.l} 0;
  background-color: ${(props) => props.theme.colors.paragraphs};

  .footer-container {
    max-width: 1136px;
    padding: 0 ${(props) => props.theme.spaces.s};
    margin: 0 auto;
  }

  p {
    margin: 0;
    line-height: 1.58;
  }

  a {
    color: ${(props) => props.theme.colors.neutral100};
    line-height: 1.58;
  }

  .footer-top {
    padding-bottom: ${(props) => props.theme.spaces.m};

    .footer-top-right {
      .social-media {
        list-style: none;
        display: flex;
        margin: ${(props) => props.theme.spaces.s} 0 0;
        padding: 0;

        li {
          &:not(:last-child) {
            margin-right: ${(props) => props.theme.spaces.s};
          }

          a,
          img {
            display: block;
          }
        }
      }
    }
  }

  .footer-bottom {
    padding-top: ${(props) => props.theme.spaces.m};
    border-top: 1px solid #bababa;

    ul {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      margin: 0 0 ${(props) => props.theme.spaces.m};
      padding: 0;

      li {
        padding-top: ${(props) => props.theme.spaces.xxs};
        padding-bottom: ${(props) => props.theme.spaces.xxs};

        &:not(:last-child) {
          position: relative;
          margin-right: ${(props) => props.theme.spaces.m};

          &:after {
            content: '';
            width: 1px;
            height: 14px;
            background-color: ${(props) => props.theme.colors.neutral100};
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -12px;
          }
        }
      }
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoint.tabletMinWidth}) {
    padding: ${(props) => props.theme.spaces.xxl4} 0;

    .footer-bottom {
      ul {
        margin-bottom: ${(props) => props.theme.spaces.s};

        li {
          &:not(:last-child) {
            margin-right: ${(props) => props.theme.spaces.l};

            &:after {
              right: -16px;
            }
          }
        }
      }
    }
  }
`;
