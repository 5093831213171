import { createSelector } from '@reduxjs/toolkit';

import { queryExtract } from './mutationQueryExtract';

/**
 * @name dfKeyExtract
 * @description Return the corresponding DF Config in state by DF Config KeyName
 * @param {state} state
 * @param {keyName} string
 * @returns {config} config array / object
 */

export const dfKeyExtract = createSelector(
  (state: any) => queryExtract(state, 'fetchHeaderConfig')?.data?.config,
  (_: any, keyName: string) => keyName,
  (configs, keyName) => {
    const result = configs?.filter(
      (config: { key: string; name: string; value: [] }) =>
        config.key === keyName
    )[0]?.value;

    return result ? result : [];
  }
);
