import React, { useEffect, useState } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import ScriptWrapper from './ScriptWrapper';
import theme from './theme';
import { isURL } from './web/helper/checker';
import DefaultLayout from './web/layouts/default';
import GlobalStyle from '@components/Based/GlobalStyle';
import { fetchHeaderConfig } from '@store/config/ConfigSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { initiateLanguage } from '@store/language/LanguageSlice';
import { preloadNjoiBoxLCP } from '@store/njoiBox/NjoiBoxSlice';
import { preloadNjoiCombo } from '@store/njoiCombo/njoiComboSlice';
import astroFibreRoutes, {
  AstroFibreDefaultLayoutLoadableComponent
} from '@web/routes/astroFibreRoutes';
import NjoiRoute from '@web/routes/NjoiRoute';
import routes from '@web/routes/routes';

const isAstroFibreURL = (): boolean =>
  window.location.pathname.includes('astro-fibre');

const App: React.FC = () => {
  const language = useAppSelector((state) => state.language.language);
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(initiateLanguage());
    dispatch(fetchHeaderConfig.initiate());
    setLoading(false);
    if (isURL('get-njoi')) {
      preloadNjoiBoxLCP();
    } else if (isURL('sign-up')) {
      preloadNjoiCombo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <div />;

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Router>
        <ScriptWrapper>
          {!isAstroFibreURL() ? (
            <DefaultLayout>
              <Switch>
                {/* remove trailing slashes in URL */}
                <Redirect
                  from="/:url*(/+)"
                  to={window.location.pathname.slice(0, -1)}
                />
                {routes.map(
                  ({ path, name, component: Component, children, exact }) => {
                    const fullPath = path ? `/${language.code}-${path}` : '/';

                    return (
                      <Route
                        key={name}
                        path={fullPath}
                        exact={exact}
                        render={() => (
                          <NjoiRoute
                            path={path}
                            component={Component}
                            childComponent={children}
                            fullPath={fullPath}
                          />
                        )}
                      />
                    );
                  }
                )}
                <Redirect to="/" />;
              </Switch>
            </DefaultLayout>
          ) : (
            <AstroFibreDefaultLayoutLoadableComponent>
              <Switch>
                <Redirect
                  from="/:url*(/+)"
                  to={window.location.pathname.slice(0, -1)}
                />
                {astroFibreRoutes.map(
                  ({ path, name, component: Component, children, exact }) => {
                    const fullPath = path
                      ? `/${language.code}-astro-fibre/${path}`
                      : '/';

                    return (
                      <Route
                        key={name}
                        path={fullPath}
                        exact={exact}
                        render={() => (
                          <NjoiRoute
                            path={path}
                            component={Component}
                            childComponent={children}
                            fullPath={fullPath}
                          />
                        )}
                      />
                    );
                  }
                )}
                <Redirect to="/" />;
              </Switch>
            </AstroFibreDefaultLayoutLoadableComponent>
          )}
        </ScriptWrapper>
      </Router>
    </ThemeProvider>
  );
};

export default App;
