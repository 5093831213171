import { NjoiCampaign } from '@jsCore/src/types/providers';
import { createSlice } from '@reduxjs/toolkit';
import { USECASE_TYPES } from 'njoi-core/inversify.config';

import { api } from '../api/api';

export const bannerSlice = createSlice({
  name: 'banner',
  initialState: {
    shouldShowBanner: true
  } as BannerState,
  reducers: {
    toggleBanner: (state) => {
      state.shouldShowBanner = !state.shouldShowBanner;
    }
  }
});

const njoiCampaignApi = api
  .enhanceEndpoints({ addTagTypes: ['njoiCampaign'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      fetchNjoiCampaign: builder.query<NjoiCampaign, void>({
        query: () => ({ useCaseType: USECASE_TYPES.GetNjoiCampaign })
      })
    })
  });

export const { useFetchNjoiCampaignQuery } = njoiCampaignApi;
export const { fetchNjoiCampaign } = njoiCampaignApi.endpoints;

export const { toggleBanner } = bannerSlice.actions;

export default bannerSlice.reducer;
