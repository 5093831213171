import React, { useEffect } from 'react';

import { AnalyticLink } from './Link';
import { Button, LinkButton } from '@components/Based/Buttons';
import useResizeScreen from '@src/hooks/useResizeScreen';
import {
  RedirectParam,
  RedirectToAppLinkProps
} from '@src/types/components/core';

const RedirectToAppLink: React.FC<RedirectToAppLinkProps> = ({
  sendEvent,
  ctaButtonText,
  param = undefined,
  size = 'default',
  outlined = false,
  onlyText = false,
  onClick
}) => {
  let timer: any;
  const isTabletScreenOrBigger = useResizeScreen(768);

  useEffect(() => {
    const listener = () => {
      if (document.visibilityState !== 'visible') {
        clearTimeout(timer);
      }
    };
    document.addEventListener('visibilitychange', listener);

    return () => {
      document.removeEventListener('visibilitychange', listener);
      clearTimeout(timer);
    };
  });

  const handleFallback = () => {
    timer = setTimeout(() => {
      window.location.href = 'https://astroinfo.co/mynjoiappweb';
    }, 2000);
  };

  return (
    <AnalyticLink url={getRedirectedLink(param)} external sendEvent={sendEvent}>
      {onlyText ? (
        <LinkButton
          size={isTabletScreenOrBigger ? 'default' : 'small'}
          onClick={() => {
            if (onClick) {
              onClick();
            }
            handleFallback();
          }}
        >
          {ctaButtonText}
        </LinkButton>
      ) : (
        <Button
          size={size}
          onClick={() => {
            if (onClick) {
              onClick();
            }
            handleFallback();
          }}
          outlined={outlined}
        >
          {ctaButtonText}
        </Button>
      )}
    </AnalyticLink>
  );
};

export const getRedirectedLink = (param?: RedirectParam) => {
  const userAgent = navigator.userAgent;
  const userParam = param ? `${param.category}/${param.id}` : '';
  if (/iPhone|iPod|iPad/.test(userAgent)) {
    return `https://apps.njoi.com.my/${userParam}`;
  } else if (/Android/.test(userAgent) || /HMSCore/.test(userAgent)) {
    return `njoi://${userParam}`;
  }
  return 'https://astroinfo.co/mynjoiappweb';
};

export default RedirectToAppLink;
