import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import bm from './bm';
import en from './en';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en
    },
    bm: {
      translation: bm
    }
  },
  debug: false,
  fallbackLng: 'bm',
  interpolation: {
    escapeValue: false
  }
});
