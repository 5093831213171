import React from 'react';

import { useTranslation, Trans } from 'react-i18next';
import LazyLoad from 'react-lazyload';

import { Row, Col } from '@components/Based/Elements';
import { WhatsappIcon, EmailIcon, FacebookIcon } from '@components/Based/icons';
import { Footer } from '@components/Based/Layout';
import { AnalyticLink } from '@components/Core/Link';
import config from '@src/config';
import { sendFooterContactUsNavigationEvent } from '@store/analytics/AnalyticSlice';
import { useAppSelector } from '@store/hooks';

// import ExpandableFooterMenu from './ExpandableFooterMenu';

const footerMenu = [
  {
    path: 'privacy-policy',
    title: 'footer.footerMenu.privacyPolicy',
    name: 'privacy-policy'
  },
  {
    path: 'privacy',
    title: 'common.privacyNotice',
    name: 'privacy-notice'
  },
  {
    path: 'website-terms-of-use',
    title: 'footer.footerMenu.termsOfUse',
    name: 'terms-of-use'
  },
  {
    path: 'terms-and-conditions',
    title: 'common.termsAndConditions',
    name: 'terms-and-conditions'
  }
];

// const aboutMenu = [
//   { path: '/media-room', title: 'Media Room', name: 'media-room' },
//   { path: '/careers', title: 'Careers', name: 'careers' },
//   { path: '/contact', title: 'Contact Us', name: 'contact' }
// ];

// const termsMenu = [
//   { path: '/diy', title: 'DIY Campaign Terms & Conditions', name: 'diy' },
//   { path: '/csr', title: 'CSR Campaign Terms & Conditions', name: 'csr' }
// ];

const socialMedia = [
  {
    url: `https://api.whatsapp.com/send?phone=${config.whatsappNumber}&text=Hi`,
    title: 'Whatsapp',
    name: 'whatsapp',
    ariaLabel: 'Whatsapp',
    icon: WhatsappIcon
  },
  {
    url: 'mailto:njoi@astro.com.my',
    title: 'Email',
    name: 'email',
    ariaLabel: 'Email',
    icon: EmailIcon
  },
  {
    url: 'https://www.facebook.com/NJOIHD/',
    title: 'Facebook',
    name: 'facebook',
    ariaLabel: 'Facebook',
    icon: FacebookIcon
  }
];

const DefaultFooter: React.FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation();

  const { language } = useAppSelector((state) => state.language);

  const getBuildVersion = () => {
    console.log(`Build version: ${process.env.REACT_APP_BUILD_VERSION}`);
  };

  return (
    <Footer className={className}>
      <div className="footer-container">
        <div className="footer-top">
          <Row justifyContent="space-between">
            {/* temporary hide this */}
            {/* <Col className="m-full-width">
              <ExpandableFooterMenu title="About NJOI" menu={aboutMenu} />
              <ExpandableFooterMenu title="Campaign Terms" menu={termsMenu} />
            </Col> */}
            <Col className="m-full-width">
              <div className="footer-top-right">
                <p id="footer-text-callus">
                  <Trans
                    i18nKey="footer.callUsText"
                    values={{
                      whatsappNumber: config.whatsappNumber
                    }}
                    components={[
                      <AnalyticLink
                        url={`https://api.whatsapp.com/send?phone=${config.whatsappNumber}&text=Hi`}
                        external={{ openInNewTab: true }}
                        sendEvent={sendFooterContactUsNavigationEvent({
                          title: 'Footer Via Whatsapp',
                          url: `https://api.whatsapp.com/send?phone=${config.whatsappNumber}&text=Hi`
                        })}
                      >
                        WhatsApp 'Hi'
                      </AnalyticLink>,
                      <AnalyticLink
                        url={`tel:${config.whatsappNumber}`}
                        external={true}
                        sendEvent={sendFooterContactUsNavigationEvent({
                          title: '60395438100',
                          url: `tel:${config.whatsappNumber}`
                        })}
                      >
                        t
                      </AnalyticLink>
                    ]}
                  />
                </p>
                <ul className="social-media">
                  {socialMedia.map((item) => (
                    <li key={item.name}>
                      <AnalyticLink
                        ariaLabel={item.ariaLabel}
                        url={item.url}
                        external={{
                          openInNewTab: true
                        }}
                        sendEvent={sendFooterContactUsNavigationEvent({
                          title: item.title,
                          url: item.url
                        })}
                      >
                        <LazyLoad height={24} offset={100}>
                          <img
                            id={item.title}
                            src={item.icon}
                            alt={item.title}
                            loading="lazy"
                            width="24"
                            height="24"
                          />
                        </LazyLoad>
                      </AnalyticLink>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>
        </div>
        <div className="footer-bottom">
          <ul className="term-menu">
            {footerMenu.map((item) => (
              <li key={item.name}>
                <AnalyticLink
                  url={`/${language.code}-${item.path}`}
                  sendEvent={sendFooterContactUsNavigationEvent({
                    title: t(item.title),
                    url: `/${language.code}-${item.path}`
                  })}
                >
                  {t(item.title)}
                </AnalyticLink>
              </li>
            ))}
          </ul>

          <p
            data-testid="footer-text-copyright"
            id="footer-text-copyright"
            onClick={getBuildVersion}
          >
            <Trans
              i18nKey="footer.copyright"
              components={[<span data-chromatic="ignore"></span>]}
              values={{
                year: new Date().getFullYear()
              }}
            />
          </p>
        </div>
      </div>
    </Footer>
  );
};

export default DefaultFooter;
