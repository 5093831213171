import { Config } from 'njoi-js-core/src/types/general';

const config: Config = {
  appName: 'acm-njoi-web-portal',
  defaultLanguage: {
    code: 'bm' as LanguageTypeISO1,
    iso: 'may' as LanguageTypeISO2
  },
  supportedLanguages: [
    {
      code: 'en',
      iso: 'eng'
    },
    {
      code: 'bm',
      iso: 'may'
    }
  ],
  announcementCloseSearchParam: 'isAnnouncementClose',
  promotionCloseSearchParam: 'isPromotionClose',
  languageName: 'language',
  whatsappNumber: '+603 9543 8100',
  cassandraLeadTokenName: 'cassandraLeadToken',
  otpResendGapInSecond: 180
};

export default config;
