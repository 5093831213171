import { USECASE_TYPES } from 'njoi-core/inversify.config.js';
import { GetNjoiBoxContent } from 'njoi-js-core/src/types/useCases';

import { skipGetNjoiRequest } from '../preloadLCP/PreloadLCPSlice';
import { store } from '@src/store';
import { getResizeImageUrl } from '@src/web/helper/imageResizer/ImageResizer';
import { api } from '@store/api/api';

export const preloadNjoiBoxLCP = async () => {
  await store.dispatch(fetchNjoiBoxData.initiate());
  store.dispatch(skipGetNjoiRequest());
  const img = new Image();
  img.src = getResizeImageUrl(
    store.getState().centralApi.queries['fetchNjoiBoxData(undefined)'].data
      ?.highlightedContent.imageUrl,
    648,
    388
  );
};

const njoiBoxApi = api
  .enhanceEndpoints({ addTagTypes: ['njoiBox'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      fetchNjoiBoxData: builder.query<GetNjoiBoxContent, void>({
        query: () => ({ useCaseType: USECASE_TYPES.GetNjoiBox })
      })
    })
  });

export const { useFetchNjoiBoxDataQuery } = njoiBoxApi;
export const { fetchNjoiBoxData } = njoiBoxApi.endpoints;
