import { createApi } from '@reduxjs/toolkit/query/react';
import { Dependency } from 'njoi-core/inversify.config';

const axiosBaseQuery =
  () =>
  async ({ useCaseType, args }: { useCaseType: any; args?: any }) => {
    try {
      const data = await Dependency.get<any>(useCaseType)(args);
      return {
        data: data
      };
    } catch (error: any) {
      return {
        error: {
          status: error.statusCode,
          data: error.error
        }
      };
    }
  };

// initialize an empty api service that we'll inject endpoints into later as needed
export const api = createApi({
  reducerPath: 'centralApi',
  baseQuery: axiosBaseQuery(),
  refetchOnMountOrArgChange: true,
  endpoints: () => ({})
});

export const { resetApiState } = api.util;
