import theme from '@src/theme';

/**
 * @name getResizeImageUrl
 * @description Return image url with different width
 * @param {string} src
 * @param {number} desktopWidth
 * @param {number} mobileWidth
 * @returns {string} image latest url
 */
export const getResizeImageUrl = (
  src: string,
  desktopWidth: number,
  mobileWidth: number
) => {
  if (!src) return '';
  let mobileBreakpoint = theme.breakpoint.mobileMinWidth;
  // To remove px from the breakpoint
  let minWidth = mobileBreakpoint.slice(0, -2);
  let width =
    window.innerWidth >= Number(minWidth) ? desktopWidth : mobileWidth;

  return `https://resizer-acm.eco.astro.com.my/tr:w-${width * 2}/${src}`;
};

/**
 * @name getOriginalImageUrl
 * @description Return original image url
 * @param {string} src
 * @returns {string} original image url
 */
export const getOriginalImageUrl = (src: string) => {
  if (src === '' || src === null || src === undefined) return '';
  const position = src.lastIndexOf('http');
  const result = src.slice(position);
  return result;
};

export const fallbackImageHandler = (
  event: React.SyntheticEvent<HTMLImageElement, Event>
) => {
  const target = event.currentTarget;
  target.onerror = null; // prevents looping
  target.src = getOriginalImageUrl(target.src);
};
