export const REDUCER_PATH = {
  afRegisterInterest: () =>
    import(
      /* webpackChunkName: "AfRegisterInterestSlice" */ '@src/store/afRegisterInterest/afRegisterInterestSlice'
    ),
  astroCart: () =>
    import(
      /* webpackChunkName: "AstroCartSlice" */ '@src/store/astroCart/AstroCartSlice'
    ),
  contactUs: () =>
    import(
      /* webpackChunkName: "ContactUsSlice" */ '@src/store/contactus/ContactUsSlice'
    ),
  njoiForm: () =>
    import(
      /* webpackChunkName: "NjoiFormSlice" */ '@src/store/njoiForm/NjoiFormSlice'
    ),
  njoiFormB40: () =>
    import(
      /* webpackChunkName: "NjoiFormSlice" */ '@src/store/njoiFormB40/NjoiFormB40Slice'
    ),
  support: () =>
    import(
      /* webpackChunkName: "SupportSlice" */ '@src/store/support/SupportSlice'
    ),
  userInfo: () =>
    import(
      /* webpackChunkName: "UserInfoSlice" */ '@src/store/astrofibre/UserInfoSlice'
    )
};
