import loadable from '@loadable/component';

import { injectReducer } from '@src/store';

export const AstroFibreDefaultLayoutLoadableComponent = loadable(
  () =>
    import(
      /* webpackChunkName: "AstroFibreDefaultLayout" */ '@layouts/astroFibre'
    )
);

export const AstroFibrePromotionLoadableComponent = loadable(
  () =>
    import(
      /* webpackChunkName: "AstroFibrePromotion" */
      '@pages/AstroFibre/Promotion'
    )
);

export const OrderAstroFibreLoadableComponent = loadable(() =>
  import(
    /* webpackChunkName: "ConfirmAstroFibreOrder" */
    '@src/web/pages/AstroFibre/ConfirmOrder/ConfirmAstroFibreOrder'
  ).then(async (module: any) => {
    await injectReducer('astroCart');
    return module;
  })
);

export const AddressCheckerLoadableComponent = loadable(() =>
  import(
    /* webpackChunkName: "AddressChecker" */ '@src/web/pages/AstroFibre/AddressChecker/AstroCart'
  ).then(async (module: any) => {
    await injectReducer('astroCart', 'userInfo');
    return module;
  })
);

export const AstroFibreThankyouLoadableComponent = loadable(() =>
  import(
    /* webpackChunkName: "AstroFibreThankyou" */
    '@pages/AstroFibre/Thankyou'
  ).then(async (module: any) => {
    await injectReducer('astroCart');
    return module;
  })
);

export const RegisterInterestLoadableComponent = loadable(
  () =>
    import(
      /* webpackChunkName: "RegisterInterest" */
      '@pages/AstroFibre/RegisterInterest'
    )
);

export const RegisterInterestInquiryLoadableComponent = loadable(
  () =>
    import(
      /* webpackChunkName: "RegisterInterest" */
      '@pages/AstroFibre/RegisterInterest/Inquiry'
    )
);
export const AstroFibreSummaryLoadableComponent = loadable(() =>
  import(
    /* webpackChunkName: "AstroFibreSummary" */
    '@pages/AstroFibre/Summary'
  ).then(async (module: any) => {
    await injectReducer('astroCart', 'userInfo');

    return module;
  })
);

export const RegisterInterestUpdateContactLoadableComponent = loadable(() =>
  import(
    /* webpackChunkName: "RegisterInterestUpdateContact" */
    '@pages/AstroFibre/RegisterInterest/UpdateContact'
  ).then(async (module: any) => {
    await injectReducer('afRegisterInterest');

    return module;
  })
);

export const RegisterInterestInquiryThankyouLoadableComponent = loadable(
  () =>
    import(
      /* webpackChunkName: "RegisterInterestThankyou" */
      '@pages/AstroFibre/RegisterInterest/Thankyou'
    )
);

export const AstroFibreUserInfoFormLoadableComponent = loadable(() =>
  import(
    /* webpackChunkName: "AstroFibreUserInfoForm" */
    '@src/web/pages/AstroFibre/UserInfoForm/UserInfoForm'
  ).then(async (module: any) => {
    await injectReducer('astroCart', 'userInfo');

    return module;
  })
);

const astroFibreRoutes = [
  {
    path: 'promotion',
    name: 'promotion',
    component: AstroFibrePromotionLoadableComponent,
    exact: true
  },
  {
    path: 'confirm-order',
    name: 'confirmastrofibreorder',
    component: OrderAstroFibreLoadableComponent,
    exact: true
  },
  {
    path: 'register-interest',
    name: 'registerinterest',
    component: RegisterInterestLoadableComponent,
    children: [
      {
        path: '',
        component: RegisterInterestInquiryLoadableComponent,
        exact: true
      },
      {
        path: 'update-contact',
        component: RegisterInterestUpdateContactLoadableComponent,
        exact: true
      },
      {
        path: 'thank-you',
        component: RegisterInterestInquiryThankyouLoadableComponent,
        exact: true
      }
    ],
    exact: false
  },
  {
    path: 'thank-you',
    name: 'thankyou',
    component: AstroFibreThankyouLoadableComponent,
    exact: true
  },
  {
    path: 'sign-up',
    name: 'signup',
    component: AstroFibreUserInfoFormLoadableComponent,
    exact: true
  },
  {
    path: 'address-checker',
    name: 'addresschecker',
    component: AddressCheckerLoadableComponent,
    exact: true
  },
  {
    path: 'order-summary',
    name: 'ordersummary',
    component: AstroFibreSummaryLoadableComponent,
    exact: true
  }
];

export default astroFibreRoutes;
