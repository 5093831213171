import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  // Use font-display: optional; (optional will wait for 100ms, if the font is not loaded, will use the browser default font)
  // so that the font will not swap from browser default font to Poppins if Poppins loaded after 100ms
  @font-face {
    font-family: "FS Albert Pro";
    src: url("https://acm-assets.eco.astro.com.my/fonts/FSAlbertPro.woff2") format("woff2"),
         url("https://acm-assets.eco.astro.com.my/fonts/FSAlbertPro.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: optional;
  }

  @font-face {
    font-family: "FS Albert Pro";
    src: url("https://acm-assets.eco.astro.com.my/fonts/FSAlbertPro-Bold.woff2") format("woff2"),
         url("https://acm-assets.eco.astro.com.my/fonts/FSAlbertPro-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: optional;
  }

  @font-face {
    font-family: "FS Albert Pro";
    src: url("https://acm-assets.eco.astro.com.my/fonts/FSAlbertPro-ExtraBold.woff2") format("woff2"),
         url("https://acm-assets.eco.astro.com.my/fonts/FSAlbertPro-ExtraBold.woff") format("woff");
    font-weight: 800;
    font-style: normal;
    font-display: optional;
  }

  :root{
    --astro-font-family: 'FS Albert Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, 'Helvetica Neue', Arial, Microsoft YaHei, 微软雅黑, STXihei,
      华文细黑, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol'
  }

  body {
    line-height: 1.25;
    font-size: 14px;
    overflow-x: hidden;

    @media (min-width: ${(props) => props.theme.breakpoint.mobileMinWidth}) {
      font-size: 16px;
    }
  }

  h1 {
    font-size: 42px;
    font-weight: 600;
    line-height: 1.13;
    color: ${(props) => props.theme.colors.paragraphs};
    
    &.special {
      font-size: 64px;
      font-weight: normal;
    }

    @media (min-width: ${(props) => props.theme.breakpoint.mobileMinWidth}) {
      font-size: 60px;

      &.special {
        font-size: 92px;
      }
    }
  }

  h2 {
    font-size: 32px;
    font-weight: bold;
    line-height: 1.2;
    color: ${(props) => props.theme.colors.paragraphs};

    &.sepcial {
      font-size: 52px;
      font-weight: normal;
    }

    @media (min-width: ${(props) => props.theme.breakpoint.mobileMinWidth}) {
      font-size: 40px;

      &.special {
        font-size: 74px;
      }
    }
  }

  h3 {
    font-size: 25px;
    font-weight: bold;
    line-height: 1.36;
    color: ${(props) => props.theme.colors.paragraphs};

    @media (min-width: ${(props) => props.theme.breakpoint.mobileMinWidth}) {
      font-size: 28px;
    }
  }

  h4 {
    font-size: 21px;
    font-weight: bold;
    line-height: 1.36;
    color: ${(props) => props.theme.colors.paragraphs};

    @media (min-width: ${(props) => props.theme.breakpoint.mobileMinWidth}) {
      font-size: 23px;
    }
  }

  h5 {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.36;
    color: ${(props) => props.theme.colors.paragraphs};

    @media (min-width: ${(props) => props.theme.breakpoint.mobileMinWidth}) {
      font-size: 18px;
    }
  }

  h6 {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.25;
    color: ${(props) => props.theme.colors.paragraphs};

    @media (min-width: ${(props) => props.theme.breakpoint.mobileMinWidth}) {
      font-size: 16px;
    }
  }

  a {
    color: ${(props) => props.theme.colors.accent};
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  button {
    cursor: pointer;
    font-size: inherit;
  }

  .p {
    &t-1 {
      padding-top: 24px;
    }
    &b-1 {
      padding-bottom: 24px;
    }
  }

  .m {
    &l-auto {
      margin-left: auto;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoint.mobileMaxWidth}) {
    .m-height-100 {
      height: 100vh;
      overflow: hidden;
    }

    .m-height-100.overflow {
      height: 100vh;
      overflow: auto;
    }

    .hide-content {
      display: none;
    }
  }
`;

export default GlobalStyle;
