import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { combineReducers } from 'redux';

// import articleDetailReducer from './articledetail/ArticleDetailSlice';
import analyticReducer from './analytics/AnalyticSlice';
import announcementReducer from './announcement/AnnouncementSlice';
import bannerReducer from './banner/BannerSlice';
import { REDUCER_PATH } from './constant';
import entitledAstroFibreReducer from './entitledAstroFibre/EntitledAstroFibreSlice';
import languageReducer from './language/LanguageSlice';
import menuAnimationReducer from './menuAnimation/menuAnimationSlice';
import metaReducer from './meta/MetaSlice';
import preloadLCPSliceReducer from './preloadLCP/PreloadLCPSlice';
import promotionReducer from './promotion/PromotionSlice';
import redirectReducer from './redirect/RedirectSlice';
import { api } from '@store/api/api';

export let existingReducer: any = {
  analytic: analyticReducer,
  announcement: announcementReducer,
  entitledAstroFibre: entitledAstroFibreReducer,
  language: languageReducer,
  meta: metaReducer,
  promotion: promotionReducer,
  redirect: redirectReducer,
  menuAnimation: menuAnimationReducer,
  preloadLCP: preloadLCPSliceReducer,
  banner: bannerReducer,
  centralApi: api.reducer
};

export const injectReducer = async (
  ...reducerNames: (keyof typeof REDUCER_PATH)[]
) => {
  const newReducer: any = {};
  const selectedReducers: Promise<any>[] = [];
  const selectedIndexes: number[] = [];
  reducerNames.forEach((name: keyof typeof REDUCER_PATH, index: number) => {
    if (!existingReducer.hasOwnProperty(name)) {
      selectedIndexes.push(index);
      selectedReducers.push(REDUCER_PATH[name]());
    }
  });
  const modules = await Promise.all(selectedReducers);
  selectedIndexes.forEach((index: number, count: number) => {
    newReducer[reducerNames[index]] = modules[count].default;
  });
  existingReducer = { ...newReducer, ...existingReducer };
  // @ts-ignore */}
  store.replaceReducer(combineReducers(existingReducer));
};

export const store = configureStore({
  reducer: existingReducer,
  devTools: {
    shouldHotReload: false
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'userInfo/addNRICFront',
          'userInfo/addNRICBack',
          'userInfo/addMyKadUtilityBill',
          'userInfo/addPassportIDPage',
          'userInfo/addPassportVisa',
          'userInfo/addPassportUtilityBill',
          'userInfo/validateUIInputs',
          'userInfo/verifyFormOkay'
        ]
      }
    }).concat([api.middleware])
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
setupListeners(store.dispatch);
