import React from 'react';

import { RoundButton } from '@components/Based/Buttons';
import { NotFoundContainer } from '@components/Based/NotFoundContainer';
import { AnalyticLink } from '@components/Core/Link';

const NotFound: React.FC = () => (
  <NotFoundContainer>
    <div className="NotFoundBox">
      <p className="NotFoundStatusCode">404</p>
      <p className="NotFoundBMContent">
        Halaman ini tidak wujud atau telah dipindahkan.
      </p>
      <p className="NotFoundENContent">
        The page you are looking for doesn’t exist or has been moved.
      </p>
      <AnalyticLink url="/">
        <RoundButton className="NotFoundCTAButton">UTAMA / HOME</RoundButton>
      </AnalyticLink>
    </div>
  </NotFoundContainer>
);

export default NotFound;
