import styled from 'styled-components';

export const NotFoundContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(180deg, #7dbf0d, #1fb1a5);

  & .NotFoundBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: ${({ theme }) => theme.colors.neutral100};
    padding: ${({ theme }) => `0 ${theme.spaces.s} 0 ${theme.spaces.s}`};
  }

  .NotFoundBox > p {
    margin: 0px;
  }

  .NotFoundBox .NotFoundStatusCode {
    font-size: 64px;
  }

  .NotFoundBox .NotFoundBMContent {
    font-size: 28px;
    font-weight: bold;
  }

  .NotFoundBox .NotFoundENContent {
    font-size: 16px;
    font-weight: 500;
    margin: ${({ theme }) => `${theme.spaces.s} 0 ${theme.spaces.m} 0`};
  }

  .NotFoundBox .NotFoundCTAButton {
    background-color: ${({ theme }) => theme.colors.neutral100};
    border-radius: 30px;
    color: ${({ theme }) => theme.colors.accent};
    font-size: 14px;
    font-weight: 500;
    width: 152px;
    height: 48px;
    box-shadow: 0 7px 12px 0 rgba(33, 154, 112, 0.3);
    border: solid 1px ${({ theme }) => theme.colors.accent};
    opacity: 1;
  }

  .NotFoundBox > a {
    text-decoration: none;
    color: inherit;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.mobileMaxWidth}) {
    .NotFoundBox .NotFoundStatusCode {
      font-size: 92px;
    }

    .NotFoundBox .NotFoundBMContent {
      font-size: 40px;
    }

    .NotFoundBox .NotFoundENContent {
      font-size: 18px;
    }
  }
`;
