import React, { useEffect, useState } from 'react';

import { HelmetProvider, Helmet } from 'react-helmet-async';
import { useLocation, useHistory } from 'react-router-dom';

import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import MarketingBar from './MarketingBar';
import config from '@src/config';
import { initAnnouncement } from '@src/store/announcement/AnnouncementSlice';
import { toggleBanner } from '@src/store/banner/BannerSlice';
import { resetMenuAnimationState } from '@src/store/menuAnimation/menuAnimationSlice';
import { initPromotion } from '@src/store/promotion/PromotionSlice';
import { initRedirect } from '@src/store/redirect/RedirectSlice';
import { isURL } from '@src/web/helper/checker';
import NotFound from '@src/web/layouts/default/NotFound';
import { useAppSelector, useAppDispatch } from '@store/hooks';

const DefaultLayout: React.FC = (props) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const meta = useAppSelector((state) => state.meta);
  const { isNotFound } = useAppSelector((state) => state.redirect);
  const { isOpenMenuAnimationEnded } = useAppSelector(
    (state) => state.menuAnimation
  );
  const dispatch = useAppDispatch();

  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [isContentRendered, setIsContentRendered] = useState<boolean>(false);

  useEffect(() => {
    // get `config.announcementCloseSearchParam` and `config.promotionCloseSearchParam` value from NJOIW webflow
    // if user close announcement bar or promotion bar or both in webflow
    // react web should not show as well
    let shouldFetchAnnouncement: boolean = true;
    let isAnnouncementClosed: string | null = null;

    let shouldFetchPromotion: boolean = true;
    let isPromotionClosed: string | null = null;

    let isCampaignClosed: string | null = null;

    const searchParams = history.location.search;

    if (searchParams) {
      const params = new URLSearchParams(searchParams);
      isAnnouncementClosed = params.get(config.announcementCloseSearchParam);
      isPromotionClosed = params.get(config.promotionCloseSearchParam);
      isCampaignClosed = params.get('isCampaignClose');

      shouldFetchAnnouncement = !(
        isAnnouncementClosed && isAnnouncementClosed === 'true'
      );

      shouldFetchPromotion = !(
        isPromotionClosed && isPromotionClosed === 'true'
      );
    }

    dispatch(initAnnouncement({ isClose: !shouldFetchAnnouncement }));
    dispatch(initPromotion({ isClose: !shouldFetchPromotion }));
    if (isCampaignClosed) {
      dispatch(toggleBanner());
    }

    // remove `config.announcementCloseSearchParam` and `config.promotionCloseSearchParam` from address url after load
    if (isAnnouncementClosed || isPromotionClosed || isCampaignClosed) {
      history.replace(`${history.location.pathname}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const content = document.getElementById('content')?.firstElementChild;
      if (content && content.childElementCount > 0) {
        setIsContentRendered(true);
        observer.disconnect();
      }
    });

    observer.observe(document, {
      attributes: false,
      childList: true,
      characterData: false,
      subtree: true
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    dispatch(initRedirect());
    window.scrollTo(0, 0);

    // Close the menu and reset menu animation state on mobile when user switch to other page
    if (window.innerWidth < 992) {
      setOpenMenu(false);
      dispatch(resetMenuAnimationState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, dispatch]);

  const toggleMenu = () => setOpenMenu((prev) => !prev);

  return (
    <HelmetProvider>
      <Helmet>
        <title>NJOI | {meta.title}</title>

        {/* default meta tag */}
        {/* for more information about meta `name` and `property`, */}
        {/* please refer to https://www.geeksforgeeks.org/whats-the-difference-between-meta-name-and-meta-property/ */}
        <meta name="description" content={meta.description} />
        <meta name="keywords" content={meta.keywords} />

        {/* Facebook tags */}
        <meta property="og:title" content={meta.ogTitle} />
        <meta property="og:description" content={meta.ogDescription} />
        {meta.ogImage && <meta property="og:image" content={meta.ogImage} />}
        <meta property="og:url" content={meta.ogUrl} />

        {/* Twitter tags */}
        <meta property="twitter:title" content={meta.twitterTitle} />
        <meta
          property="twitter:description"
          content={meta.twitterDescription}
        />
        <meta property="twitter:image" content={meta.twitterImage} />
        <meta property="twitter:url" content={meta.twitterUrl} />

        {process.env.REACT_APP_ENV !== 'production' ? (
          <meta name="robots" content="noindex" />
        ) : null}

        <link rel="canonical" href={meta.url} />
      </Helmet>
      {!isNotFound ? (
        <div
          id="main"
          className={
            openMenu && isOpenMenuAnimationEnded
              ? 'm-height-100 overflow'
              : openMenu
              ? 'm-height-100'
              : ''
          }
        >
          {(!isURL('get-njoi', 'sign-up') || isContentRendered) && (
            <MarketingBar />
          )}
          <DefaultHeader openMenu={openMenu} toggleMenu={toggleMenu} />
          <main
            id="content"
            className={
              openMenu && isOpenMenuAnimationEnded ? 'hide-content' : undefined
            }
            data-testid="content"
          >
            {props.children}
          </main>
          {isContentRendered && (
            <DefaultFooter
              className={
                openMenu && isOpenMenuAnimationEnded ? 'hide-content' : ''
              }
            />
          )}
        </div>
      ) : (
        <NotFound />
      )}
    </HelmetProvider>
  );
};

export default DefaultLayout;
